// const { VITE_API_ENDPOINT } = import.meta.env;

import { INTEGRATIONS_DOMAIN } from "@launchos/shared-utils/env";

export default {
  // serverUrl: `${INTEGRATIONS_DOMAIN}:48081`,
  // serverUrl: `http://localhost:8081`,
  // serverUrl: ``,
  serverUrl: INTEGRATIONS_DOMAIN,
  // prospectUrl: {
  //   limelight: "http://localhost:1000/subscribe",
  //   activecampaign: "http://localhost:10001/subscribe",
  //   drip: "http://localhost:10002/subscribe",
  //   getresponse: "http://localhost:10003/subscribe",
  //   icontact: "http://localhost:10004/subscribe",
  //   convertkit: "http://localhost:10005/subscribe",
  //   mailChimp: "http://localhost:10006/subscribe",
  //   ontraport: "http://localhost:10007/subscribe",
  //   launchOS: `${INTEGRATIONS_DOMAIN}/api/launchOS`,
  // },
  prospectUrl: {
    limelight: "/api/limelightProspect",
    mailChimp: "/api/mailChimpProspect",
    getresponse: "/api/getresponseProspect",
    icontact: "/api/icontactProspect",
    sendreach: "/api/sendreachProspect",
    activecampaign: "/api/activecampaignProspect",
    convertkit: "/api/convertkitProspect",
    drip: "/api/dripProspect",
    ontraport: "/api/ontraportProspect",
    launchOS: "/api/launchOS"
  },
  orderUrl: {
    limelight: "/api/limelightOrder",
    stripe: "/api/stripeOrder",
    authorize: "/api/authorizeOrder",
    paypal: "/api/paypalOrder"
  },
  upsellUrl: {
    limelight: "/api/limelightUpsell",
    stripe: "/api/stripeUpsell",
    authorize: "/api/authorizeUpsell",
    paypal: "/api/paypalUpsell"
  }
};

// export default module.exports;