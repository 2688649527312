// import EditorObject from "@launchos/modules/editor/Builder/EditorObject";

// import Debug from "@launchos/modules/editor/Builder/EditorObject/Debug";
// import * as Tools from "@launchos/modules/editor/Builder/Properties";

// import StyleBuilder from "@launchos/modules/v2/Properties/components/properties/StyleBuilder";

import _, { uniq, get } from "lodash";
import React, { useContext } from "react";
import { PictureContent } from "./content";

// import LazyImage from './LazyImage'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// import theme from "@launchos/components/ui/theme";
import EditorObject from "@/Apps/Pages/EditorObject";
import * as Tools from "@/Apps/Pages/Properties";
import { PageContext } from "@/Apps/Pages/Page/PageContext";
import Dropzone from "react-dropzone";
import { DropZoneWrapper } from "@/Apps/Pages/Properties/BuildingBlocks/UploadGroup";
import { EditorMode } from "@/Apps/Pages/Editor/types";

export const showImage = (files, props) => {
  if (files.length) {
    const file = _.head(files);

    if (!_.has(file, "preview")) {
      Object.assign(file, { preview: URL.createObjectURL(file) });
    }

    props.updateComponentSettings(
      props.settings.id,
      {
        ...props.settings,
        src: file.preview,
      },
      true,
      0
    );
  }

  console.log("Detected Upload", files);
};

const PictureContentEdit = (props) => {
  return (
    <EditorObject {...props} PropertiesView={Properties}>
      <div
        style={{
          padding: props.padding,
          cursor: /*props.show === "preview" ? "inherit" :*/ "grab",
        }}
      >
        <PictureContent {...props} />
      </div>
    </EditorObject>
  );
};

const BasicPropertiesTab = (props) => (
  <>
    <Tools.UploadGroup
      createMediaItem={props.createMediaItem}
      uploadURLToS3={props.uploadURLToS3}
      settings={props.settings}
      url={props.settings.src}
      accept="image/jpeg, image/png"
      highlighted
      onChange={(files) => {
        showImage(files, props);
      }}
      isExpanded
      label="Upload New Picture"
    />
    <Tools.Interactions
      label="Picture Actions"
      {...props}
      onUpdate={(action) => console.log(action)}
    >
      <div />
    </Tools.Interactions>
    <Tools.WidthHeight label="Picture Size" {...props} />
    <Tools.PositionPadding {...props} />
    <Tools.Alignment {...props} verticalAlign={false} textAlign />
  </>
);

const AdvancedPropertiesTab = (props) => {
  // const [style, setStyle] = React.useState(props.settings.properties);

  // const updateStyle = (property) => {
  //   setStyle({ ...style, ...property });
  //   console.log(property);
  //   props.updateComponentStyle(props.settings.id, property);
  // };

  return (
    <>
      <Tools.BordersShadow {...props} isExpanded />
      {/* <Tools.Section
        label="Manual Styling"
        icon="vertical_align_center"
        {...props}
      > */}
      {/* <StyleBuilder
          style={style}
          onChange={newStyle => setStyle({ ...style, ...newStyle })}
          onBlur={newStyle => updateStyle(newStyle)}
        /> */}
      {/* <Debug {...props} /> */}
      {/* </Tools.Section> */}
    </>
  );
};

export const Properties = (props) => (
  <div>
    <Tools.PropertiesWindow
      {...props}
      defaultTab="basic"
      tabs={[
        { id: "basic", title: "Picture", component: BasicPropertiesTab },
        { id: "advanced", title: "Advanced", component: AdvancedPropertiesTab },
      ]}
    />
  </div>
);

export const SimplePictureProperties = (props) => {
  const { settings, updateComponentSettings } = props;
  const { completedItems, updateCompletedItems } = useContext(PageContext);

  return (
    <div className="px-3">
      {/* <h3 className="text-sm font-medium">{settings?.prompt}</h3> */}
      <div className="rounded-lg my-5" style={{}}>
        <Tools.UploadGroup
          createMediaItem={props.createMediaItem}
          uploadURLToS3={props.uploadURLToS3}
          settings={props.settings}
          url={props.settings.src}
          accept="image/jpeg, image/png"
          highlighted
          onChange={(files) => {
            showImage(files, props);
            const isComplete = completedItems.includes(settings.id);
            if (!isComplete)
              updateCompletedItems([...completedItems, settings.id]);
          }}
          isExpanded
          hideLabel={true}
        />
      </div>
    </div>
  );
};

export const SimplePictureUploadOverlay = (props) => {
  const { mode } = props;
  if (mode === EditorMode.EDITOR) return props.children;

  return (
    <DropZoneWrapper
      {...props}
      accept="image/jpeg, image/png"
      onChange={(files) => showImage(files, props)}
    >
      <div className="absolute inset-0 z-50 flex items-center justify-center cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-200">
        <div className="px-5 py-3 text-sm bg-white/90 rounded shadow-sm hover:bg-white/95 transition-colors duration-200">
          <div className="flex items-center">
            <i className="material-icons text-sm">upload</i>
            <span className="ml-1">Click to upload</span>
          </div>
        </div>
      </div>
      
    </DropZoneWrapper>
  );
};

// const style = {
//   coverObject: {
//     width: "100%",
//     zIndex: 50,
//     position: "absolute",
//     height: "100%",
//     textAlign: "center",
//     // paddingTop: 100,
//     opacity: 0.4,
//   }
// }

export default (props) => {
  const { settings } = props;
  const marginTop = settings.properties ? settings.properties.marginTop : 0;
  const padding = settings.properties ? settings.properties.padding : 0;
  const textAlign = settings.properties
    ? settings.properties.textAlign
    : "inherit";
  return (
    <div style={{ marginTop, textAlign }}>
      {settings.src.indexOf("blob") > -1 && (
        <div
          style={{
            position: "absolute",
            padding: 5,
            background: "rgba(210,210,210,0.85)",
            zIndex: 10,
            fontSize: "9pt",
            display: "inline-block",
            width: 85,
            textAlign: "left",
            bottom: 6,
          }}
        >
          Uploading &nbsp;&nbsp;{" "}
          <span style={{ position: "absolute" }}>
            <Loader type="TailSpin" color="#00BFFF" height={10} width={10} />
          </span>
        </div>
      )}
      <PictureContentEdit {...props} padding={padding} ignoreHref />
    </div>
  );
};
