import * as React from "react";
import { get } from "lodash";
import { parseLiquidObject } from "../../Page/LiquidRenderer";

interface V1ObjectWrapperProps {
  settings: any;
  children: React.ReactNode;
  showOverlay?: boolean;
}

/**
 * This object should wrap around every component (both live and editor versions).
 * It ideally won't be needed in the next version of components
 *
 * Currently, it overrides the marginTop and textAlign properties in order to
 * make the editor object (and live webobject) "hug" the inner component
 */
export const V1ObjectWrapper: React.FC<V1ObjectWrapperProps> = ({
  settings,
  forceStyle = {},
  children,
}) => {
  const { properties } = settings;
  const marginTop = get(properties, "marginTop", 0);
  const textAlign = get(properties, "textAlign", "inherit");
  const width = "100%";
  const maxWidth = get(properties, "maxWidth", get(properties, "width", "inherit"));
  const display = get(properties, "display", "block");
  const height = get(properties, "height", "inherit");

  return (
    <div style={{ marginTop, width, maxWidth, textAlign, height }}>
      <div style={{ display, width, maxWidth, ...forceStyle }}>{children}</div>
    </div>
  );
};

export const convertProperties = (properties) => ({
  ...properties,
  marginTop: 0,
});

export default V1ObjectWrapper;
