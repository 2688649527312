import React, { useContext } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { ContainerProps, ContainerContentProps } from "./types";

import { CURSOR_ID, EditorMode } from "@/Apps/Pages/Editor/types";
import { ShadowStyles } from "@/Apps/Pages/Properties/Sections/BorderShadow/types";
import { PageContext } from "@/Apps/Pages/Page/PageContext";
import {
  getRepeatContent,
  parseLiquidObject,
} from "@/Apps/Pages/Page/LiquidRenderer";
import { ContainerContext } from "./ContainerContext";
import V1ObjectWrapper from "../../v2/V1ObjectWrapper";

// import { ShadowStyles } from "@launchos/modules/editor/Builder/Properties/Sections/BorderShadow/types";
// enum ShadowStyles { OUTSIDE = "Outside", INSIDE = "Inside" }

export const ContainerContent: React.FC<ContainerContentProps> = (props) => {
  const {
    settings,
    pageContent = [],
    mode = EditorMode.LIVE,
    EmptyState,
  } = props;

  const childContent = pageContent.filter((itm) => itm.parent === settings.id);

  const isEmpty = childContent.length === 0;
  const isEmptyWithCursor =
    childContent.length === 1 && childContent[0].id === CURSOR_ID;

  const style = props?.style || props.properties;

  const defaultStyle: React.CSSProperties = {
    padding: get(settings.properties, "padding", "inherit"),
    height: get(settings.properties, "height", "inherit"),

    // Flex Styles
    display: "flex",
    flexDirection: get(settings.properties, "flexDirection", "column"),
    gap: get(settings.properties, "gap", "inherit"),
  };

  const containerContentStyle: React.CSSProperties = parseLiquidObject({
    ...style,
    backgroundImage: get(settings.properties, "backgroundImage", "inherit"),
    backgroundSize: get(settings.properties, "backgroundSize", "inherit"),
    backgroundPosition: get(
      settings.properties,
      "backgroundPosition",
      "inherit"
    ),
    backgroundRepeat: get(settings.properties, "backgroundRepeat", "inherit"),
    borderWidth: get(settings.properties, "borderWidth", "inherit"),
    borderStyle: get(settings.properties, "borderStyle", "inherit"),
    borderRadius: get(settings.properties, "borderRadius", "inherit"),
    borderColor: get(settings.properties, "borderColor", "inherit"),
    ...(get(settings.properties, "shadowStyle") === ShadowStyles.OUTSIDE
      ? { boxShadow: get(settings.properties, "boxShadow", "inherit") }
      : {}),
    overflow: "hidden",
  });

  const bW: number = Number(
    String(containerContentStyle.borderWidth).replace("rem", "")
  );

  const style2: React.CSSProperties = parseLiquidObject({
    backgroundImage: `none`,
    ...(get(settings.properties, "shadowStyle") === ShadowStyles.INSIDE
      ? {
          boxShadow: get(settings.properties, "boxShadow", "inherit"),
        }
      : {}),
    backgroundColor: get(settings.properties, "backgroundColor"),
    // if a border exists (borderWidth > 0), do nothing (let the border radius apply to the parent div)
    ...(bW > 0
      ? {}
      : { borderRadius: get(settings.properties, "borderRadius", "inherit") }),
    ...defaultStyle,
  });

  return (
    <div style={containerContentStyle}>
      <div style={style2}>
        {(isEmpty || isEmptyWithCursor) && mode !== EditorMode.LIVE ? (
          <EmptyState {...props} />
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};

export const Container: React.FC<ContainerProps> = (props) => {
  const handleClick = () => {
    const {
      dispatch,
      settings,
      cKey,
      fieldValues,
      updateComponentSettings,
      getComponentSettings,
    } = props;
    const { actions } = settings;

    if (actions) {
      const doTheseActions = actions.filter((itm) => itm.behavior === "click");
      doTheseActions.forEach((action) =>
        dispatch({
          ...action,
          settings: { ...settings, cKey, fieldValues },
          updateComponentSettings,
          getComponentSettings,
        })
      );
    }
  };

  const properties = props.settings.properties;
  // const properties = parseLiquidObject(props.settings.properties);
  const marginTop = properties.marginTop || -1; // temporarily fixes this problem: https://ambid.slack.com/archives/G164PAK7D/p1560526689091500
  const defaultPosition = marginTop < 0 ? "relative" : "inherit"; // this lets objects with a negative marginTop overlap nearby objects
  const position = get(properties, "position", defaultPosition);
  const width = get(properties, "width");
  const { actions = false } = props.settings;

  // Jan 31, 2025 -> Removed this because it was causing the container to not be styled correctly
  // const containerContentStyle: React.CSSProperties = {
  //   padding: get(properties, "padding"),
  //   backgroundColor: get(properties, "backgroundColor", "inherit"),
  //   backgroundImage: get(properties, "backgroundImage", "inherit"),
  //   backgroundSize: get(properties, "backgroundSize", "inherit"),
  //   backgroundPosition: get(properties, "backgroundPosition", "inherit"),
  //   backgroundRepeat: get(properties, "backgroundRepeat", "inherit"),
  //   borderWidth: get(properties, "borderWidth", "inherit"),
  //   borderStyle: get(properties, "borderStyle", "inherit"),
  //   borderColor: get(properties, "borderColor", "inherit"),
  //   boxShadow: get(properties, "boxShadow", "inherit"),
  //   shadowColor: get(properties, "shadowColor", "inherit"),
  //   shadowOffset: get(properties, "shadowOffset", "inherit"),
  //   shadowRadius: get(properties, "shadowRadius", "inherit"),
  // };

  // repeat if requested
  const repeat = props?.settings?.data?.name;

  // get value to loop
  const toLoopAround = getRepeatContent(repeat) || "";
  const data = toLoopAround.split("\n");

  return (
    <div
      data-testid="WC-CONTAINER-LIVE"
      style={{
        justifyContent: "normal",
        display: "block",
        padding: "1px 0 0 0", // temporarily fixes this problem: https://ambid.slack.com/archives/G164PAK7D/p1560526689091500
        height: properties.height || "inherit",
        width,
        marginTop,
        position,
        cursor: actions ? "pointer" : "inherit",
      }}
      onClick={handleClick}
    >
      {data.map((itm, key) => {
        return (
          <ContainerContext.Provider key={key} value={{ [repeat]: itm }}>
            <ContainerContent
              {...props}
              // style={containerContentStyle}
              mode={EditorMode.LIVE}
            />
          </ContainerContext.Provider>
        );
      })}
    </div>
  );

  // return (
  //   <div style={{ display: "block", width: "100%" }}>
  //     {/* Fix this somehow (only required when looping so containers don't show left/right) */}
  //     {data.map((itm, key) => {
  //       return (
  //         <div
  //           data-testid="WC-CONTAINER-LIVE"
  //           key={key}
  //           style={{
  //             justifyContent: "normal",
  //             display: "block",
  //             padding: "1px 0 0 0", // temporarily fixes this problem: https://ambid.slack.com/archives/G164PAK7D/p1560526689091500
  //             height: properties.height || "inherit",
  //             width,
  //             marginTop,
  //             position,
  //             cursor: actions ? "pointer" : "inherit",
  //           }}
  //           onClick={handleClick}
  //         >
  //           <ContainerContext.Provider value={{ [repeat]: itm }}>
  //             <ContainerContent
  //               {...props}
  //               style={containerContentStyle}
  //               mode={EditorMode.LIVE}
  //             />
  //           </ContainerContext.Provider>
  //         </div>
  //       );
  //     })}
  //   </div>
  // );
};

// export default connect()(Container);
export default Container;
