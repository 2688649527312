import React, { Component } from "react";
import { get } from 'lodash'

// import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
// import { updateSection } from "@launchos/modules/v2/Properties/components/generator";

import {
  PropertiesWindow,
  Section,
  Alignment,
  Margin,
  Typography,
  Background,
  Padding,
  Spacing,
  BorderShadow as BordersShadow,
} from "@/Apps/Pages/Properties";
import { Tabs, Tab } from "@material-ui/core";

import { TimeDateOptions } from "./TimeDateOptions";
import { MoreTimeOptions } from "./MoreTimeOptions";

// import { getRenderer } from "./renderer";

// import { EditorMode } from "@launchos/modules/v2/Editor/types";
// import countdownSettings from "../settings";

const BasicPropertiesTab = (props) => {
  return (
    <>
      <TimeDateOptions {...props} isExpanded />
      <MoreTimeOptions {...props} />
      <Section label="Position" icon="import_export">
        <Margin {...props} />
      </Section>
      <Alignment {...props} textAlign verticalAlign={false} />
    </>
  );
}

const AdvancedPropertiesTab = (props) => {
  const [tab, switchTab] = React.useState<number>(0);
  const { updateComponentSettings, settings } = props;
  return (
    <>
      <Section label="Typography" isExpanded icon="text_format">
        <Tabs value={tab} onChange={(event: React.ChangeEvent<{}>, newTab: number) => switchTab(newTab)}>
          <Tab label="Numbers" value={0} />
          <Tab label="Labels" value={1} />
        </Tabs>
        <div style={{ padding: 20 }}>
          {tab === 0 && <Typography {...props} isExpanded hideLabel />}
          {tab === 1 && <Typography {...props} isExpanded hideLabel updateComponentStyle={(id, properties, dbUpdate, forceUpdate) => {
            const newObject = {
              ...settings,
              labelStyle: {
                ...get(settings, 'labelStyle', {}),
                ...properties
              }
            }
            console.log({ newObject, properties })
            updateComponentSettings(id, newObject, dbUpdate, forceUpdate)
          }} />}
        </div>
      </Section>
      <Background {...props} showAlpha />
      <Section label="Padding & Spacing" icon="border_clear">
        <Padding {...props} />
        <Spacing {...props} />
      </Section>
      <BordersShadow {...props} />
    </>
  )
};

export const Properties = (props) => (
  <PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[
      { id: "basic", title: "Countdown", component: BasicPropertiesTab },
      { id: "advanced", title: "Advanced", component: AdvancedPropertiesTab },
    ]}
  />
);

export const SimpleCountdownProperties = props => {
  const { settings } = props;
  return (
    <div className="px-3">
      {/* <h3 className="text-sm font-medium">{settings?.prompt}</h3> */}
      <div className="rounded-lg my-5" style={{ zoom: "75%" }}>
        <TimeDateOptions {...props} hideLabel isExpanded />
      </div>
    </div>
  );
}

export default Properties;
