// import { PageContext } from "@/Apps/Pages/Page/PageContext";
import { ComponentTypes } from "@/Apps/Pages/Editor/types";
import { PageContext } from "@/Apps/Pages/Page/PageContext";
import { RichEditor } from "@/Apps/Pages/Properties";
import { RichEditorPosition } from "@/Apps/Pages/Properties/RichEditor";
import { wrapWithAncestorBackgrounds } from "@/Apps/Pages/Properties/RichEditor/RichEditor";
import {
  addThisAfterThat,
  clearDuplicatedItemCollection,
  duplicateItem,
  listChildren,
  recursivelyDuplicate,
  removeItem,
  updateComponentSettings,
} from "@/Apps/Pages/V2Editor/actions";
// import { listAncestors, listChildren } from "@/Apps/Pages/V2Editor/actions";
import React, { useContext, useEffect, useState } from "react";

export const SimpleContainerProperties: React.FC = (props) => {
  const [textContent, setTextContent] = useState("");
  const { activeContent = [], setContent = () => {} } = useContext(PageContext);
  const { completedItems, updateCompletedItems } = useContext(PageContext);

  const settings = props?.settings || {}; // Ensure settings is always defined

  const getIdsOfChildren = (id) => {
    const children = listChildren(activeContent, id);
    // console.log("listChildren", children);
    if (!children) return [];
    return children.children.map((itm) => itm.id);
  };

  const findFirstTextObject = (children: any): any => {
    if (!children || !children.children) return null;

    for (const child of children.children) {
      if (child.type === "Text") {
        return child;
      }
      if (child.children && child.children.length > 0) {
        const found = findFirstTextObject(child);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const getContent = () => {
    // Find all of the id's of the first child of the selected container
    const ids = getIdsOfChildren(settings.id);

    const contentArray = ids.map((id) => {
      const children = listChildren(activeContent, id);
      const textObject = findFirstTextObject(children);
      return textObject?.html;
    });

    // And add the settings.html to an array
    const html = contentArray.map((html) => `<li>${html}</li>`).join("");
    // console.log("found", html);
    return html;

    // Use that as the text field's bullet point content
  };

  const handleChange = (html) => {
    const getListItemsFromHTML = (html) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const listItems = Array.from(doc.querySelectorAll("li")).map(
        (li) => li.innerHTML
      );
      return listItems;
    };

    // Ensure settings.id exists before proceeding
    if (!settings?.id) return;

    // if the current item is not in the completedItems array, add it
    const isComplete = completedItems.includes(settings.id);
    if (!isComplete) updateCompletedItems([...completedItems, settings.id]);

    // get the list items (li's) in the textbox
    const listItems = getListItemsFromHTML(html);

    // store a backup of the first child in the first children content
    const firstChildIds = getIdsOfChildren(settings?.id);
    const firstChildItems = duplicateItem(activeContent, firstChildIds[0]);

    // Guard against empty firstChildIds
    if (!firstChildIds.length) return;

    console.log({ listItems, firstChildIds, firstChildItems });

    // recursively remove all the first children in the content
    let newContent = activeContent;
    for (let i = 0; i < firstChildIds.length; i++) {
      newContent = removeItem(newContent, firstChildIds[i]);
    }

    console.log({ activeContent, newContent });

    let newContent2 = newContent;

    let newTextObjects = [];

    // create a loop around each list item
    for (let i = 0; i < listItems.length; i++) {
      // create a copy of the first child backup
      // update the html of the first text field in that first child with the list item's html
      const childrenOfFirstChild = duplicateItem(
        activeContent,
        firstChildIds[0]
      );
      const textObjectToClone = childrenOfFirstChild.find(
        (itm) => itm.type === ComponentTypes.TEXT
      );
      console.log({ childrenOfFirstChild, textObjectToClone });

      const newTextObject = {
        ...textObjectToClone,
        html: listItems[i],
      };

      // // console.log({ newTextObject });

      // update the html of the first text field in that first child with the list item's html
      // create a new childItem list, but with new text item
      const key = childrenOfFirstChild.findIndex(
        (itm) => itm.type === ComponentTypes.TEXT
      );

      const newChildItems = [
        ...childrenOfFirstChild.slice(0, key),
        newTextObject,
        ...childrenOfFirstChild.slice(key + 1),
      ];

      // append that to the content
      newTextObjects.push(...newChildItems);
    }

    console.log({ newTextObjects });

    // // update the page with that content
    const key = newContent2.findIndex((itm) => itm.id === settings.id);
    newContent2 = [
      ...newContent2.slice(0, key + 1),
      ...newTextObjects,
      ...newContent2.slice(key + 1),
    ];

    // console.log({ newContent2 });

    setContent(newContent2);
  };

  // const content = getContent();
  // useEffect(() => {
  //   setTextContent(getContent());
  // }, [activeContent]);
  useEffect(() => {
    setTextContent(getContent());
    // console.log({ textContent: getContent() });
  }, []);

  return (
    <div className="px-3">
      {/* <h3 className="text-sm font-medium">{settings?.prompt}</h3> */}
      <div className="rounded-lg my-5">
        {wrapWithAncestorBackgrounds(
          <RichEditor
            id={`ed_${settings.id}`}
            // content={settings.html}
            content={textContent}
            // style={generateInlineStyle(settings)}
            buttonList={[
              ["bold", "italic", "underline", "strikethrough"],
              // ["bullist", "numlist"],
              // ["outdent", "indent"],
              // ["lineheight"],
              ["link"],
              // ["removeformat"],
            ]}
            menuList={[
              // ["undo", "redo"],
              // usesSmartCopy ? ["smartcopy"] : [],
              ["fontfamily", "googlefonts", "fontsizes"],
              ["forecolor", "backcolor"],
              ["link"],
            ]}
            position={RichEditorPosition.TOP}
            // onChange={handleChange}
            onChange={(html) => {
              console.log({ html });
              setTextContent(html);
              handleChange(html);
            }}
          />,
          settings.id
        )}
      </div>
    </div>
  );
};
