// import { ComponentRenderHooks } from "@launchos/plugins/types";
import * as React from "react";
import _, { keys } from "lodash";
import reducers from "./reducers";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { ButtonProps, PluginProps } from "./types";
// import { ComponentTypes } from "../../types";
// import { EditorMode } from "@/Apps/Pages/Editor/types";

// import Icon from "../Icon";
// import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

// import { parseValue } from "@launchos/modules/editor/Builder/Page/scripts";

import { parseValue } from "@/Apps/Pages/Page/scripts/getInputValues"
import { ComponentRenderHooks } from "@/plugins/types";
import { ComponentTypes, EditorMode } from "@/Apps/Pages/Editor/types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import Icon from "../Icon/live";
import { LiquidRenderer, parseLiquidObject, parseLiquidString } from "@/Apps/Pages/Page/LiquidRenderer";
import { useStripeContext } from "../PaymentElement/live";


/**
 * A button
 */
export const Button: React.FC<ButtonProps> = (settings) => {
  const {
    caption1,
    caption2,
    caption1style,
    caption2style,
    style,
    properties,
    hoverStyle,
    icon,
    iconSource,
    iconStyle,
    onClick,
    href = "",
    mode = EditorMode.LIVE,
    disabled = false,
    // dispatch,
    // cKey,
    // fieldValues,
    updateComponentSettings,
    getComponentSettings,
    actions = [],
  } = settings;

  const [isHovering, setIsHovering] = React.useState(false);
  const isInStripeContext = useStripeContext();
  
  const stripe = isInStripeContext ? useStripe() : {};
  const elements = isInStripeContext ? useElements() : {};

  const handleButtonClick = () => {
    if (actions) {
      const doTheseActions = actions.filter((itm) => itm.behavior === "click");
      doTheseActions.forEach((action) => {
        console.log("doTheseActions", { action })
        reducers([], {
          ...action,
          settings,          
          context: { stripe, elements, cardIsPresent: true },
          updateComponentSettings,
          getComponentSettings,
        })
      });
    }
  };

  const btn = (
    <button
      data-testid="WC-BUTTON-LIVE"
      type={actions[0]?.type}
      style={{
        cursor: mode === EditorMode.LIVE ? "pointer" : "grab",
        ...parseLiquidObject(style),
        ...parseLiquidObject(properties),
        ...(isHovering ? hoverStyle : {}),
        // display: "flex",
      }}
      // onClick={onClick}
      disabled={disabled}
      onClick={onClick || handleButtonClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div style={{ display: "flex" }}>
        {icon && (
          // <div style={{ display: "inline-block", ...iconStyle }}>
          <div style={{ display: "flex", alignSelf: "center" }}>
            <Icon
              type={icon}
              source={iconSource}
              style={{ color: "white", fontSize: "32pt", ...iconStyle }}
            />
          </div>
        )}

        <div
          style={{
            padding: 4,
            width: "100%",
            display: "flex",
            textAlign: "center",
            alignSelf: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            {caption1 && (
              <div
                style={{ width: "100%", padding: "0 10px", ...parseLiquidObject(caption1style) }}
              >
                {/* <span dangerouslySetInnerHTML={{ __html: parseValue(caption1)}} /> */}
                <LiquidRenderer html={parseLiquidString(parseValue(caption1))} />
              </div>
            )}
            {caption2 && (
              <div
                style={{ width: "100%", padding: "0 10px", ...parseLiquidObject(caption2style) }}
              >
                {/* <span dangerouslySetInnerHTML={{ __html: parseValue(caption2)}} /> */}
                <LiquidRenderer html={parseLiquidString(parseValue(caption2))} />
              </div>
            )}
          </div>
        </div>
      </div>
    </button>
  );

  // if (href.length) return <a href={href}>{btn}</a>;
  return btn;
};

const ButtonPlugin: React.FC<PluginProps> = ({
  settings,
  updateComponentSettings,
  getComponentSettings,
}) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <Button
        {...settings}
        updateComponentSettings={updateComponentSettings}
        getComponentSettings={getComponentSettings}
        properties={properties}
      />
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.BUTTON
  ) {
    return [ButtonPlugin];
  }
};

// export default connect()(Button);
export default Button;
