import Button from "@/ui/Button";
import { DropDownProps } from "@/ui/Dropdown/types";
import { ButtonTypes } from "@/ui/types";

interface TextPromptGroupProps extends DropDownProps {
  label?: string;
  isExpanded?: boolean;
  onClick?: () => void;
  onChange?: () => void;
  children?: JSX.Element | JSX.Element[];
}

export const TextPromptGroup: React.FC<TextPromptGroupProps> = ({
  label = "Settings",
  value,
  prompt = "Enter a value",
  onChange = () => {},
  isExpanded = true,
  children,
}) => {
  const handleClick = () => {
    const response = window.prompt(prompt, value);
    if (response) {
      onChange(response);
    }
  };

  return (
    <div className="rounded-lg dark:hover:bg-white/5 hover:bg-black/5 cursor-pointer">
      <div className="flex items-center justify-between p-3">
        <h3 className="text-sm font-medium text-black/40 dark:text-white/40">{label}</h3>
        <Button
          type={ButtonTypes.SOFT}
          label={value || "Click to edit"}
          style={{ padding: "10px 18px", fontSize: "10pt" }}
          onClick={handleClick}
        />
      </div>
      {isExpanded && <p className="text-xs text-gray-500 px-3">{children}</p>}
    </div>
  );
};
