import { Switch } from "@/ui/Switch";

interface SwitchGroupProps {
    label?: string;
    caption?: string | JSX.Element;
    isExpanded?: boolean;
    isChecked?: boolean;
    className?: string;
    onClick?: () => void;
    onSwitchChange?: () => void;
    children?: JSX.Element | JSX.Element[];
}

export const SwitchGroup: React.FC<SwitchGroupProps> = ({
    label = "Settings",
    caption,
    isExpanded = true,
    isChecked = false,
    className = "py-5",
    onClick = () => { },
    onSwitchChange = () => { },
    children,
}) => {
    return (
      <div
        className={className}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        <div className="rounded-lg dark:hover:bg-white/5 hover:bg-black/5 pb-3 cursor-pointer">
          <div className="flex items-center justify-between p-3">
            <h3 className="text-sm font-medium text-black/40 dark:text-white/40">
              {label}
            </h3>
            <Switch
              htmlFor="enable"
              checked={isChecked}
              onChange={onSwitchChange}
            />
          </div>
          {caption && (
            <p className="text-sm text-gray-500 px-3 pb-3">{caption}</p>
          )}
          {isExpanded && (
            <p className="text-sm text-gray-500 px-3">{children}</p>
          )}
        </div>
      </div>
    );
};