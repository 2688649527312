import { get, head } from "lodash";
import React from "react";
// import { useDimensions } from "@/plugins/utilities";
import { useDimensions } from "../utils";
// import { Button } from "@launchos/components/ui";

import DragHandle from "./DragHandle";
import { DropDownMenu } from "./DropDownMenu";
import Debug from "./Debug";
import { Border } from "./style";
import { EditorMode } from "../Editor/types";
import { HookTriggers } from "@/plugins/types";
import { ComponentRenderHooks } from "@/plugins/types";
import { triggerHook } from "@/plugins/client";

const Overlay = ({ width, height }) => {
  return (
    <div
      style={{
        position: "absolute",
        width,
        height,
        zIndex: 1,
      }}
    />
  );
};

const ObjectContentNoPreview = (props) => {
  const [isContextMenuShowing, setIsContextMenuShowing] = React.useState(null);
  const [ref, hoverBoundingRect] = useDimensions();
  const {
    children,
    settings,
    color,
    showOverlay,
    show,
    dragHandle = true,
    onRightClick,
    borderSize,
    isDragInProgress,
    isEditing,
    changeState,
    mode = EditorMode.EDITOR,
    // toggleAddNewDrawer,
    // setActiveAddNewTab
  } = props;

  const { state } = settings;

  const handleContextMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isDragInProgress && !isEditing) {
      changeState(settings.id, "active");
      setIsContextMenuShowing({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
  };

  const showExtras =
    (state === "hover" || state === "active") && show !== "preview";

  const OverlayComponent = head(
    triggerHook(
      HookTriggers.onComponentRender,
      { id: ComponentRenderHooks.WEBCOMPONENT_OVERLAY, mode },
      settings,
    )
  );

  return (
    <div
      ref={ref}
      onContextMenu={onRightClick || handleContextMenuClick}
      style={{ textAlign: "left", ...props.style }}
    >
      {showExtras && dragHandle && mode === EditorMode.EDITOR && (
        <DragHandle
          {...props}
          showProperties={props.showProperties}
          isContextMenuShowing={isContextMenuShowing}
          setIsContextMenuShowing={setIsContextMenuShowing}
        />
      )}
      {showExtras && (
        <>
          {mode === EditorMode.EDITOR && (
            <DropDownMenu
              {...props}
              anchorEl={isContextMenuShowing}
              handleDropDownClose={() => setIsContextMenuShowing(null)}
            />
          )}
          <Border
            borderSize={borderSize}
            show={show}
            settings={settings}
            color={typeof v === "function" ? color(settings.state) : color}
            state={state}
          />
        </>
      )}
      <div style={props.style}>
        {get(props, "debug") && <Debug {...props} />}
        {showOverlay && <Overlay {...hoverBoundingRect} />}
        {OverlayComponent && mode === EditorMode.SIMPLE && <OverlayComponent {...props} />}
        {children}
      </div>
    </div>
  );
};

export const ObjectContent = (props) => {
  if (get(props, "show") === "preview") return props.children;
  return <ObjectContentNoPreview {...props} />;
};
