import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "./Card";
import { DownCarrotIcon } from "@/ui/Icons";
import ListDropdown from "@/ui/ListDropdown";
import { startCase, toLower } from "lodash";

export const ItemGroup = ({
  title = "",
  caption,
  amountToShow,
  customCardStyles = {},
  items = [],
  categories = [],
  withFilter = false,
  href = "#",
  tagFilter = [],
  children,
  onItemClick = (item: any) => null,
}) => {

  const [renderedItems, setRenderedItems] = useState(items);
  const [filterLabel, setFilterLabel] = useState("All Pages");

  useEffect(() => {
    setRenderedItems(items);
  }, [items]);

  function htmlToPlainText(htmlString) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  }

  if (items.length === 0) return null;

  const useTheseItems = renderedItems.length > 0 ? renderedItems : items;

  return (
    <div>
      <LaunchpadTitle
        title={title}
        caption={caption}
        withAll={Boolean(amountToShow)}
        href={href}
        withFilter={withFilter}
        filterLabel={filterLabel}
        filterItems={categories.map((cat) => ({
          id: cat,
          label: `${startCase(toLower(cat))} Pages`,
          onClick: () => {
            setFilterLabel(`${startCase(toLower(cat))} Pages`);
            setRenderedItems(
              items.filter((item) => {
                const cats = item?.categories?.split(",") || [];
                return cats.includes(`${cat} page`);
              })
            );
          },
        }))}
      />
      {children}
      <div className="grid gap-7 sm:grid-cols-3 justify-center mb-7">
        {useTheseItems
          .filter((item) => {
            if (tagFilter.length === 0) return true;
            const tags = item?.categories ? item?.categories.split(",") : [];
            // console.log({ tags, tagFilter });
            return tags.some(
              (tag) => tagFilter.includes(tag.replace(" page", "")) // some times " page" is added to the end of the tag
            );
          })
          .slice(0, amountToShow)
          .map((item, key) => {
            const tags = item?.categories ? item?.categories.split(",") : [];

            return (
              <Card
                key={key}
                title={item.name}
                backgroundImage={item.image}
                price={item.price}
                // just show the first 100 characters
                description={
                  Boolean(item.description?.length)
                    ? `${htmlToPlainText(item.description)?.slice(0, 40)}...`
                    : ""
                }
                customStyles={customCardStyles}
                onClick={() => onItemClick(item)}
                tags={tags || []}
              />
            );
          })}
      </div>
    </div>
  );
};

export const LaunchpadTitle = ({
  title = "",
  caption,
  withAll = false,
  withFilter = false,
  filterLabel = "Filter",
  filterItems = [],
  href = "#",
}) => {
  return (
    <div className="mt-7 mb-7">
      <div className="grid sm:grid-cols-2 justify-center items-center">
        <h3 className="text-2xl font-semibold">{title}</h3>
        {withFilter && (
          <ListDropdown
            element={
              <div className="flex justify-end">
                <div className="flex gap-1">
                  <span className="">{filterLabel}</span>
                  <DownCarrotIcon className="pt-1" />
                </div>
              </div>
            }
            items={filterItems}
          />
        )}
        {withAll && (
          <Link className="text-lightpurple-300 mt-7 mb-7 text-right" to={href}>
            See all
          </Link>
        )}
      </div>
      {caption && (
        <h3 className="text-lg text-black/40 dark:text-white/40">{caption}</h3>
      )}
    </div>
  );
};
