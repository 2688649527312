import * as React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import {
  SortableListProps,
  AddButtonComponentProps,
  EditButtonComponentProps,
  DeleteButtonComponentProps,
  UpDownButtonComponentProps
} from "./types";

import theme from "@/ui/theme";
import { cancelActions } from "@/Apps/Pages/utils";
import { IconTypes } from "@/Apps/Pages/WebComponents/v2/Icon/types";
import Icon from "@/Apps/Pages/WebComponents/v2/Icon/live";

const styles = {
  BUTTON: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    marginTop: 10,
  },
};

const Placeholder = () => (
  <button style={styles.BUTTON}>
    <Icon type={IconTypes.Cancel} style={{ color: "transparent" }} />
  </button>
);

// const StyledButton = styled.button`
//   width: 100%;
//   padding: 10px;
//   margin-top: 20px;
//   background-color: white;
//   border: 1px solid #ddd;
//   border-bottom-width: 2px;
//   border-radius: 4px;
//   cursor: pointer;
//   color: ${theme.primaryColor};
//   &:hover {
//     background-color: #f4f4f4;
//   }
// `;

const DefaultAddButton: React.FC<AddButtonComponentProps> = ({
  onAdd,
  name,
}) => (
  <span
    data-testid="Properties-SortableList-DefaultAddButton"
    style={{ display: "flex", alignItems: "center" }}
  >
    {/* <StyledButton onClick={onAdd}> */}
      <div
        className={`
          flex w-full p-2.5 mt-5 bg-white dark:bg-black/70 
          border border-black/10 dark:border-white/10 rounded cursor-pointer
          hover:bg-black/10 hover:dark:bg-white/10
        `}
        onClick={onAdd}
      >
        <span className="bg-transparent border-black/10 dark:border-white/10 rounded px-1 pt-1">
          <Icon style={{ fontSize: '18pt' }} type={IconTypes.Add} />
        </span>
        <div className="p-1.5 text-md">Add New {name}</div>
      </div>
    {/* </StyledButton> */}
  </span>
);

const DefaultEditButton: React.FC<EditButtonComponentProps> = ({
  onEdit,
  payload,
}) => (
  <span data-testid="Properties-SortableList-DefaultEditButton">
    <button onClick={() => onEdit(payload)} style={styles.BUTTON}>
      <Icon type={IconTypes.Edit} />
    </button>
  </span>
);

const DefaultDeleteButton: React.FC<DeleteButtonComponentProps> = ({
  onDelete,
  payload,
}) => (
  <span data-testid="Properties-SortableList-DefaultDeleteButton">
    <button onClick={() => onDelete(payload)} style={styles.BUTTON}>
      <Icon type={IconTypes.Cancel} />
    </button>
  </span>
);

const DefaultUpDownButton: React.FC<UpDownButtonComponentProps> = ({
  onChange,
  payload,
}) => {
  const [hovering, setIsHovering] = React.useState<"UP" | "DOWN" | false>(false);
  return (
    <span data-testid="Properties-SortableList-DefaultUpDownButton">
      <div style={{ ...styles.BUTTON, zoom: 0.65, padding: "0 15px", position: 'relative', left: 10 }}>

        <span onMouseEnter={() => setIsHovering("UP")} onMouseLeave={() => setIsHovering(false)}>
          <Icon
            style={{ ...hovering === "UP" ? { transform: `scale(1.5)` } : {} }}
            type={hovering === "UP" ? IconTypes.ArrowDropUp : IconTypes.KeyboardArrowUp}
            onClick={() => onChange('UP', payload)}
          />
        </span>

        <span onMouseEnter={() => setIsHovering("DOWN")} onMouseLeave={() => setIsHovering(false)}>
          <Icon
            style={{ ...hovering === "DOWN" ? { transform: `scale(1.5)` } : {} }}
            type={hovering === "DOWN" ? IconTypes.ArrowDropDown : IconTypes.KeyboardArrowDown}
            onClick={() => onChange('DOWN', payload)}
          />
        </span>

      </div>
    </span>
  )
};

export const SortableList: React.FC<SortableListProps> = ({
  id = "droppable",
  data = [],
  ItemComponent,
  name = "Item",
  AddButtonComponent = DefaultAddButton,
  EditButtonComponent = DefaultEditButton,
  DeleteButtonComponent = DefaultDeleteButton,
  UpDownButtonComponent = DefaultUpDownButton,
  showCRUDOnHover = true,
  showDragHandle = true,
  canDragAndDrop = true,
  canDelete = true,
  canAdd = true,
  canEdit = true,
  nested = false,
  showItemInline = true,
  lockYAxis = false,
  style = {},
  itemClass = "",
  itemStyle = {},
  itemHoverStyle = {},
  onChange = () => null,
  onEdit = () => null,
  onAdd = () => null,
  onDelete = () => null,
}) => {


  // a little function to help us with reordering the result
  const reorder = (result, startIndex, endIndex) => {
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // const getItemStyle = (isDragging, draggableStyle) => ({
  //   // some basic styles to make the items look a bit nicer
  //   userSelect: "none",
  //   padding: 16,
  //   margin: `0 0 16px 0`,

  //   // change background colour if dragging
  //   background: isDragging ? "lightgreen" : "grey",

  //   // styles we need to apply on draggables
  //   ...draggableStyle
  // });
  const getItemStyle = (provided, snapshot) => {
    const style = provided.draggableProps.style
    if (lockYAxis) {
      if (style.transform) {
        const axisLockY =
          "translate(0px" +
          style.transform.slice(
            style.transform.indexOf(","),
            style.transform.length
          );
        return {
          ...style,
          transform: axisLockY
        };
      }
    }
    return style;
  }

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "transparent" : "",
    // padding: 16,
    // width: 250
    ...style,
  });

  const onDragEnd = (result) => {

    console.log("onDragEnd", { result })

    // dropped outside the list
    if (!result.destination) return;

    const reorderedItems = reorder(
      data,
      result.source.index,
      result.destination.index
    );

    // setItems(reorderedItems);
    onChange(reorderedItems)
  }

  const SortableItem: React.FC<any> = (sortableItemProps) => {
    const [isHovering, setIsHovering] = React.useState<boolean>(false);

    const handleDelete = (payload) => {
      // send back the payload as well as a mutated version of the payload
      const { id } = payload;
      const updatedData = data.filter((itm) => itm.id !== id);
      // console.log({ payload, data, updatedData });

      onDelete(payload, updatedData);
    };

    const handleUpDownChange = (direction, payload) => {
      const { id } = payload;

      const key = data.findIndex(itm => itm.id === id)

      let items;

      if (direction === "UP") {
        if (key === 0) return;
        items = reorder(data, key, key - 1)
      }

      if (direction === "DOWN") {
        if (key === data.length - 1) return;
        items = reorder(data, key, key + 1)
      }
      // console.log({ direction, payload, data, items })
      onChange(items)
    }

    return (
      <>
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseMove={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          data-testid="Properties-SortableList-Item"
          className={itemClass}
          style={{
            display: "flex",
            ...itemStyle,
            ...(isHovering ? itemHoverStyle : {}),
          }}
        >
          {(!nested && (isHovering || !showCRUDOnHover) && showDragHandle) ? (
            <span
              data-testid="Properties-SortableList-DragHandle"
            // style={{ position: "absolute", marginLeft: -25 }}
            >
              <button
                data-testid="Properties-SortableList-DragHandle"
                style={{ ...styles.BUTTON, cursor: "move" }}
              >
                <Icon type={IconTypes.DragIndicator} />
              </button>
            </span>
          ) : (
            (showDragHandle && !nested) && <Placeholder />
          )}

          {(nested && (isHovering || !showCRUDOnHover) && showDragHandle) ? (
            <span
              data-testid="Properties-SortableList-DragHandleUpDown"
            // style={{ position: "absolute", marginLeft: -25 }}
            >
              <UpDownButtonComponent onChange={handleUpDownChange} payload={sortableItemProps} />
            </span>
          ) : (
            showDragHandle && <Placeholder />
          )}

          {showItemInline ? (
            <ItemComponent {...sortableItemProps} isHovering={isHovering} />
          ) : (
            <div style={{ width: "100%" }} />
          )}
          {(isHovering || !showCRUDOnHover) && canEdit ? (
            <EditButtonComponent onEdit={onEdit} payload={sortableItemProps} />
          ) : null}
          {(isHovering || !showCRUDOnHover) && canDelete ? (
            <DeleteButtonComponent
              onDelete={handleDelete}
              payload={sortableItemProps}
            />
          ) : null}
        </div>
        {!showItemInline && (
          <ItemComponent
            {...sortableItemProps}
            isHovering={isHovering}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          />
        )}
      </>
    );
  };

  const Content = (
    <Droppable droppableId={`droppable-${id}`}>
      {(provided, snapshot) => (
        <div
          data-testid="Properties-SortableList"
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {data.map((itm, key) => (
            <Draggable key={itm.id} draggableId={itm.id} index={key}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  // style={getItemStyle(
                  //   snapshot.isDragging,
                  //   provided.draggableProps.style
                  // )}
                  style={getItemStyle(provided, snapshot)}

                  onClick={cancelActions}
                  onMouseDown={cancelActions}
                  onContextMenu={cancelActions}
                  onDrag={cancelActions}
                  onDragStart={cancelActions}
                >
                  <SortableItem {...itm} iKey={key} />
                  {/* - Testing 123 {itm.id} - */}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
          {canAdd && <AddButtonComponent onAdd={onAdd} name={name} />}
        </div>
      )}
    </Droppable>
  )

  if (nested) return Content;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {Content}
    </DragDropContext>
  );
}

export default SortableList;
