import _ from 'lodash';
import styled from "styled-components";

const getWidthString = (mKey, settings, currentMobileState, pageContent) => {
  if (!mKey) return;

  // console.log(settings.type);

  const span = settings[mKey] === undefined ? settings.md : settings[mKey];
  const width = (span / 100) * 100;

  // how many is there now?
  // const childContent = pageContent.filter(itm => itm.parent === settings.id);
  const siblings = _.filter(
    pageContent,
    (itm) => itm.parent === settings.parent
  );
  const parent = _.head(
    _.filter(pageContent, (itm) => itm.id === settings.parent)
  );
  const colCount = siblings.length;

  // how many do we need?
  const maxColumns = _.get(
    parent,
    `properties.mobile[${currentMobileState.type}].maxColumnsPerRow`,
    colCount
  );
  // console.log({ settings, type: currentMobileState.type, siblings, colCount, maxColumns });

  // if need = less than max, scale it
  if (maxColumns <= colCount) {
    // filter columns so we just have an array of widths
    const cols = _.map(siblings, (itm) => itm["md"]);

    // which group of widths pertain to me?
    const k = _.findIndex(siblings, (itm) => itm.id === settings.id) + 1;

    // divide array by maxColumns
    const group = parseInt(k / maxColumns, 10) + (!(k % maxColumns) ? 0 : 1);

    // sum up the group that i pertain to
    const groupVals = cols.slice(
      (group - 1) * maxColumns,
      (group - 1) * maxColumns + maxColumns
    );
    const sCol = _.sum(groupVals);

    if (
      currentMobileState.type === "smartphone" &&
      !_.has(
        parent.properties,
        `mobile[${currentMobileState.type}].maxColumnsPerRow`
      )
    )
      return `width: 100%`;

    let w = (width * 100) / sCol;

    return `width: ${w}%`;
  }

  return `width: ${width}%`;
};

const ColumnWrapper = styled.div`
  ${({ $mKey, $settings, $type, $pageContent }) =>
    $mKey ? getWidthString($mKey, $settings, $type, $pageContent) : "width: 100%"};
  box-sizing: border-box;
  display: inline-block;
  padding: 0;
  margin: 0;
  transition: 0.25s;
`;

export default ({ settings, currentMobileState, pageContent = [], children }) => {
  const hash = {
    smartphone: "xs",
    tablet: "sm",
    desktop: "md",
    // fullscreen: "lg",
    fullscreen: "md", // because lg & md keep conflicting, so let's lock them together
  };
  const mKey = hash[currentMobileState.type];

  return (
    <ColumnWrapper
      $mKey={mKey}
      $settings={settings}
      $type={currentMobileState}
      $pageContent={pageContent}
    >
      {children}
    </ColumnWrapper>
  );
};
