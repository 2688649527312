import * as React from "react";
import { get } from "lodash";
import { PropertiesProps } from "../types";

import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { updateSection } from "@/Apps/Pages/Properties/components/generator";

import bulletBuilderSettings from "../settings";

import { ListBuilder } from "./ListBuilder";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import { IconTypes } from "../../Icon/types";
import { useContext } from "react";
import { PageContext } from "@/Apps/Pages/Page/PageContext";
// import { IconTypes } from "../../Icon";

/**
 * The property window that shows for the FormBuilderEditorObject
 */
export const Properties: React.FC<PropertiesProps> = (props) => {
  const [settings, setSettings] = React.useState(props.settings);

  const handleSettingsChange = (id, settings, dbUpdate = true) => {
    const { updateComponentSettings } = props;
    setSettings(settings);
    if (dbUpdate) updateComponentSettings(id, settings); //, true, false);
  };

  const { properties } = bulletBuilderSettings;
  const { sections } = properties.main;

  const withListBuilder = updateSection(sections, "BulletBuilderSettings", {
    component: (
      <ListBuilder
        {...settings}
        onChange={(data) =>
          handleSettingsChange(settings.id, { ...props.settings, data })
        }
      />
    ),
  });

  const withIconSelector = updateSection(
    withListBuilder,
    SectionTypes.ICONSELECTOR,
    {
      onChange: (e) =>
        handleSettingsChange(settings.id, {
          ...props.settings,
          icon: IconTypes[e],
        }),
    }
  );

  const withSize = updateSection(withIconSelector, "customIconSize", {
    onChange: (e) => {
      switch (e) {
        case "GROW": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "1pt").replace("pt")
            ) + 5;

          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: {
                ...get(settings, "iconStyle", {}),
                fontSize: `${value}pt`,
              },
            }
            // true,
            // 0
          );
          break;
        }
        case "SHRINK": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "6pt").replace("pt")
            ) - 5;

          // const value = convertFromRem(get(settings, "properties.fontSize"))

          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: {
                ...get(settings, "iconStyle", {}),
                fontSize: `${value}pt`,
              },
            }
            // true,
            // 0
          );
          break;
        }
        default: {
          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: {
                ...get(settings, "iconStyle", {}),
                fontSize: `${e}pt`,
              },
            }
            // true,
            // 0
          );
        }
      }
    },
  });

  const withPosition = updateSection(withSize, "customIconPosition", {
    // value: get(settings, "properties.fontSize", "1pt").replace("pt", ""),
    onChange: (e) => {
      console.log("size", e);
      switch (e) {
        case "GROW": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "1pt").replace("pt")
            ) + 5;
          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: {
                ...get(settings, "iconStyle", {}),
                marginTop: `${value}pt`,
              },
            }
            // true,
            // false
          );
          break;
        }
        case "SHRINK": {
          const value =
            parseInt(
              get(settings, "properties.fontSize", "6pt").replace("pt")
            ) - 5;
          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: {
                ...get(settings, "iconStyle", {}),
                marginTop: `${value}pt`,
              },
            }
            // true,
            // false
          );
          break;
        }
        default: {
          handleSettingsChange(
            settings.id,
            {
              ...props.settings,
              iconStyle: {
                ...get(settings, "iconStyle", {}),
                marginTop: `${e}pt`,
              },
            }
            // false,
            // false
          );
        }
      }
    },
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withPosition },
  };

  return <PropertiesBuilder data={updatedProperties} {...props} />;
};

export const SimpleListProperties = (props) => {
  const { completedItems, updateCompletedItems } = useContext(PageContext);
  const { settings, updateComponentSettings } = props;

  const { data } = settings;

  const handleChange = (e) => {

    // if the current item is not in the completedItems array, add it
    const isComplete = completedItems.includes(settings.id);
    if (!isComplete) updateCompletedItems([...completedItems, settings.id]);

    const lines = e.target.value.split("\n").filter((line) => line.trim());
    const data = lines.map((line) => ({
      ...settings?.data[0],
      id: Math.random().toString(36).substring(7),
      caption: line,
      style: {},
    }));
    updateComponentSettings(settings.id, { ...settings, data });

    // const { html } = settings;
    // updateComponentSettings(settings.id, { ...settings, html });
  };

  return (
    <div className="px-3">
      {/* <h3 className="text-sm font-medium">{settings?.prompt}</h3> */}
      <div className="rounded-lg my-5" style={{}}>
        <textarea
          onChange={handleChange}
          className="w-full h-96 dark:text-white text-black bg-transparent" 
          value={data?.map(item => item.caption).join('\n')}
        />
      </div>
    </div>
  );
};

export default React.memo(Properties);
