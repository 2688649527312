import * as React from "react";
import { get } from "lodash";

// import LazyLoad from "react-lazyload";

import { ComponentRenderHooks } from "@/plugins/types";
import { ComponentTypes, EditorMode } from "@/Apps/Pages/Editor/types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";

import { SectionProps, PluginProps } from "./types";
import { parseLiquidObject } from "@/Apps/Pages/Page/LiquidRenderer";

export const Section: React.FC<SectionProps> = ({
  children,
  style,
  properties,
  mode = EditorMode.LIVE,
  id,
  testId = "WC-SECTION-LIVE",
}) => {


  const RenderElem = (
    <section
      data-testid={testId}
      style={{
        backgroundImage: get(
          style,
          "backgroundImage",
          get(properties, "backgroundImage")
        ),
        backgroundSize: get(
          style,
          "backgroundSize",
          get(properties, "backgroundSize")
        ),
        backgroundPosition: get(
          style,
          "backgroundPosition",
          get(properties, "backgroundPosition")
        ),
        backgroundRepeat: get(
          style,
          "backgroundRepeat",
          get(properties, "backgroundRepeat")
        ),
        // cursor: mode === EditorMode.EDITOR ? "move" : "inherit",
      }}
    >
      <div
        style={parseLiquidObject({
          ...style,
          ...properties,
          maxWidth: "100%",
          width: "100%",
          backgroundImage: "none",
          backgroundColor: get(
            style,
            "backgroundColor",
            get(properties, "backgroundColor")
          ),
        })}
      >
        <div
          style={{
            width:
              get(style, "width", false) || get(properties, "width", "100%"),
            maxWidth:
              get(style, "maxWidth", false) ||
              get(properties, "maxWidth", "100%"),
            margin: "0 auto",
          }}
        >
          {children}
        </div>
      </div>
    </section>
  )

  // if (mode === EditorMode.LIVE) 
  // return (
  //   <LazyLoad unmountIfInvisible offset={100} height={100}>
  //     {RenderElem}
  //   </LazyLoad>
  // );
  // else return RenderElem
  return RenderElem

};

const SectionPlugin: React.FC<PluginProps> = ({ settings, children }) => {
  const properties = convertProperties(settings.properties);
  return (
    <V1ObjectWrapper settings={settings}>
      <Section {...settings} properties={properties}>
        {children}
      </Section>
    </V1ObjectWrapper>
  );
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    payload.type === ComponentTypes.SECTION
  ) {
    return [SectionPlugin];
  }
};

export default Section;
