import { get, has } from "lodash";
import React, { Component } from "react";
import { DragSource } from "react-dnd";
import ReactJson from "react-json-view";
// import LazyLoad from "react-lazyload";

import { MainObject } from "./MainObject";
import ResizeHandles from "./ResizeHandles";
import { EditorMode } from "../Editor/types";
import { ObjectContent } from "./ObjectContent";
const CURSOR_ID = 1111;

const boxSource = {
  beginDrag({ addHoverCursor, updateDragState, settings, moveThisByThat }) {
    // place hover cursor where original object is
    addHoverCursor(settings.id);
    updateDragState(true);

    return {
      settings,
      moveThisByThat,
    };
  },

  endDrag({ updateDragState, settings, moveThisByThat }) {
    moveThisByThat(settings.id, CURSOR_ID);
    setTimeout(() => {
      updateDragState(false);
    }, 250);

    console.log("END DRAG!!", settings);
  },

  canDrag({ settings, getCanDrag, show }) {
    if (!getCanDrag() /*|| show === "preview"*/) return false;
    return (
      get(settings, "state", "normal") === "hover" ||
      get(settings, "state", "normal") === "active"
    );
  },
};

const DragSourceHOC = DragSource("box", boxSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))(MainObject);

class EditorObject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showResize: false,
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleMouseEvent = this.handleMouseEvent.bind(this);
  }

  handleKeyPress(e) {
    e.stopPropagation();

    const {
      updateComponentStyle,
      // allowKeyboardNudging = true,
      removeItem,
      settings,
      pageContent,
      isEditing,
      changeState,
    } = this.props;

    const activeKey = pageContent.findIndex((itm) => itm.state === "active");
    const activeObj = pageContent[activeKey];

    // delete key
    if (!isEditing && e.keyCode === 46) {
      // get active id
      if (activeObj) {
        if (!activeObj.preventDelete) removeItem(activeObj.id);
      }
    }

    if (e.keyCode === 27) {
      changeState(settings.id, "normal");
    }

    // // Down Arrow Key
    // if (e.keyCode === 40 && allowKeyboardNudging) {
    //   const { marginTop } = get(settings, "properties", {});
    //   if (marginTop) {
    //     updateComponentStyle(settings.id, { marginTop: marginTop + 10 });
    //   }
    // }

    // // Up Arrow Key
    // if (e.keyCode === 38 && allowKeyboardNudging) {
    //   const { marginTop } = get(settings, "properties", {});
    //   if (marginTop) {
    //     updateComponentStyle(settings.id, { marginTop: marginTop - 10 });
    //   }
    // }
  }

  handleMouseEvent(e, type) {
    const {
      settings,
      changeState = () => null,
      isDragInProgress,
      isEditing,
    } = this.props;

    // determine if any property windows are showing
    const windows = get(this.props, "getActivePropertyWindows", () => null)();
    const activeObject = this.props.pageContent.filter(
      (itm) => itm.state === "active"
    );

    // is the id of this active object in one of the stored active property windows?
    // if so, it means the property window is showing right now
    const isShowingAnyPropertyWindows = activeObject.length
      ? windows.findIndex((id) => id === activeObject[0].id) > -1
      : false;

    switch (type) {
      case "enter":
        if (this.props.hasOwnProperty("onMouseEnter")) {
          this.props.onMouseEnter(e);
        } else if (isShowingAnyPropertyWindows) {
          // do this when there's a property window open
          if (!isDragInProgress && !isEditing) {
            e.stopPropagation();
            changeState(settings.id, "hover");
          }
        }
        break;

      case "leave":
        if (this.props.hasOwnProperty("onMouseLeave")) {
          this.props.onMouseLeave(e);
        } else if (!isDragInProgress && !isEditing) {
          e.stopPropagation();
          changeState(settings.id, "normal");
        }
        break;

      case "move":
        if (this.props.hasOwnProperty("onMouseMove")) {
          this.props.onMouseMove(e);
        }
        // else {
        if (isShowingAnyPropertyWindows) break;

        if (!isDragInProgress && !isEditing) {
          // do this when NO property windows are open
          e.stopPropagation();
          if (get(settings, "state", "normal") !== "hover") {
            changeState(settings.id, "hover");
          }
        }
        // }

        break;

      case "click":
        if (this.props.hasOwnProperty("onClick")) {
          this.props.onClick(e);
        } else {
          e.stopPropagation();
          if (!isDragInProgress && !isEditing) {
            changeState(settings.id, "active");
            this.props.onItemSelect &&
              this.props.onItemSelect(settings.id, settings);
          }
        }
        break;

      case "mousedown":
        if (this.props.hasOwnProperty("onMouseDown")) {
          if (isShowingAnyPropertyWindows) break;
          this.props.onMouseDown(e);

          e.stopPropagation();
        }
        break;

      case "mouseup":
        if (this.props.hasOwnProperty("onMouseUp")) {
          if (isShowingAnyPropertyWindows) break;

          this.props.onMouseUp(e);
        }
        break;

      case "doubleClick":
        if (this.props.hasOwnProperty("onDoubleClick")) {
          this.props.onDoubleClick(e);
        } else {
          e.stopPropagation();
          if (!isDragInProgress && !isEditing) {
            this.props.showProperties();
          }
        }
        break;

      default:
        break;
    }
  }

  render() {
    const {
      settings,
      PropertiesView,
      showingProperties,
      showProperties,
      hideProperties,
      debug = false,
      isResizable = false,
      show,
      showResize,
      mode = EditorMode.EDITOR,
    } = this.props;

    const displayStyle = {
      display: get(settings, "properties.display"),
      position: get(settings, "properties.position", "relative"),
      width: get(settings, "properties.width"),
      height: get(settings, "properties.height"),
    };

    const propertiesViewComponent = PropertiesView ? (
      <PropertiesView
        {...this.props}
        id={settings.id}
        showProperties={showProperties}
        hideProperties={hideProperties}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ cursor: "default" }}
      />
    ) : null;

    return (
      <div
        style={{ height: "100%", ...displayStyle, ...this.props.style }} // this is not shown on the published page, which causes inconsistencies
        onMouseLeave={(e) => this.handleMouseEvent(e, "leave")}
        onMouseEnter={(e) => this.handleMouseEvent(e, "enter")}
        onMouseMove={(e) => this.handleMouseEvent(e, "move")}
        onClick={(e) => this.handleMouseEvent(e, "click")}
        onMouseDown={(e) => this.handleMouseEvent(e, "mousedown")}
        onMouseUp={(e) => this.handleMouseEvent(e, "mouseup")}
        onDoubleClick={(e) => this.handleMouseEvent(e, "doubleClick")}
        onKeyUp={this.handleKeyPress}
        ref={(node) => (this.editorObject = node)}
        tabIndex="0"
      >
        {(get(settings, "state") === "active" ||
          get(settings, "state") === "hover" ||
          this.state.showResize) &&
          isResizable && <ResizeHandles {...this.props} />}

        {showingProperties &&
          get(settings, "state") === "active" &&
          propertiesViewComponent}

        {debug && <ReactJson src={settings} collapsed />}

        <DragSourceHOC
          {...this.props}
          displayStyle={displayStyle}
          showProperties={showProperties}
          showResize={showResize}
          draggable={mode === EditorMode.EDITOR}
        >
          {/* <LazyLoad unmountIfInvisible offset={900} height={100}> */}
          {this.props.children}
          {/* </LazyLoad> */}
        </DragSourceHOC>
        
      </div>
    );
  }
}

EditorObject.defaultProps = {
  showOverlay: false,
  // isResizable: false,
  resizeHandles: [],
  color: "#8b6e9a",
  dragHandle: true,
};

export default EditorObject;
