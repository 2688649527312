import * as React from "react";
// import { triggerHook } from "@launchos/plugins/client";

import WebComponent from "../WebComponent";
// import { EditorMode } from "../../../v2/Editor/types";

// import { CanvasProps } from "./types";

// import { getResponsiveSettings } from "./scripts";

import { triggerHook } from "@/plugins/client";
import { EditorMode } from "../Editor/types";
import { getResponsiveSettings } from "../Page/scripts";
import { EditorProps } from "./types";

export interface CanvasProps extends EditorProps {
  pageContent: any;
  show?: string;
  getMobileState: () => { type: string };
  editorContainer: any;
}

const Canvas: React.FC<CanvasProps> = (props) => {
  const [isDragInProgress, setIsDragInProgress] = React.useState<boolean>(
    false
  );

  const { pageContent, getMobileState } = props;

  const updateDragState = (val, callbackFn = () => false) => {
    setIsDragInProgress(val);
    callbackFn();
  };

  // Let's find out when the page is done loading
  React.useEffect(() => {
    const isFromIframe = window.location !== window.parent.location;
    // send message to parent iframe that the pages is loaded
    if (isFromIframe) {
      const message = { type: "onEditorHasLoaded" };
      window.parent.postMessage(message, "*");
    }
  }, []);

  // recursively loop through content prop to display editor content
  // load the component & pass along props
  // along with the edit=true prop (that way component can show editor version)
  const generatePage = (content, parent = false) => {
    const node = [];

    let i = 0;

    content
      .filter((c) => c?.parent === parent)
      .forEach((c) => {
        i += 1;

        const wcProps = {
          ...props,
          settings: c,
          rSettings: getResponsiveSettings(c, getMobileState()),
          isDragInProgress,
          updateDragState,
          mode: props?.mode || EditorMode.EDITOR,
        };

        let internal = c.html || generatePage(content, c.id);
        const noChildList = ["input", "br", "img"];
        if (noChildList.indexOf(c.type.toLowerCase()) > -1) internal = null;

        // node[i] = <div>{c.type}{internal}</div>
        node[i] = (
          <WebComponent
            triggerHook={triggerHook}
            // mode="editor"
            {...wcProps}
            key={c.id}
            type={c.type}
          >
            {internal}
          </WebComponent>
        );
      });

    return node;
  };

  return pageContent !== null ? generatePage(pageContent) : null;
};

export default Canvas;
