import { ComponentRenderHooks } from "@/plugins/types";
import Text, { Properties, SimpleTextProperties } from "./Text";
import settings from "./settings";

export const onListItems = (hook, payload, actions) => {
  if (hook.id === ComponentRenderHooks.WEBCOMPONENT) {
    return [settings];
  }
};

export const onComponentRender = (hook, payload, actions) => {
  if (
    hook.id === ComponentRenderHooks.WEBCOMPONENT &&
    (payload.type === "Paragraph" || payload.type === "Text")
  ) {
    return [Text];
  }

  if (hook.id === ComponentRenderHooks.PROPERTIES && hook.type === "Text") {
    return Properties(payload);
  }

  if (hook.id === ComponentRenderHooks.SIMPLE_PROPERTIES && hook.type === "Text") {
    return SimpleTextProperties(payload);
  }
};
