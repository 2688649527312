import { useEffect, useState } from "react";
import { getCatalogItem, getCatalogItems } from "@/api/catalog";
// import { useEditorActions } from "@/Apps/Pages/Editor/EditorCanvas";
import SectionWrapper from "../SectionWrapper";
import DropdownGroup from "../../groups/DropdownGroup";
import { getPage } from "@/api/pages";
import { getSessionToken } from "@/api/auth";
import { get } from "lodash";
import { client } from "@/client";
import { GET_PAGE } from "@/api/gql/pages";
// import { DEFAULT_PAGE_ID } from "@/Apps/Pages/Editor/types";

export const xdefaultColorPalettes = [
  {
    id: "ocean-breeze",
    label: "Ocean Breeze",
    values: {
      primary: "#3B82F6",
      secondary: "#2DD4BF",
      accent: "#67E8F9", 
      background: "#BAE6FD"
    }
  },
  {
    id: "sunset-glow",
    label: "Sunset Glow", 
    values: {
      primary: "#F97316",
      secondary: "#FBBF24", 
      accent: "#FDE047",
      background: "#F87171"
    }
  },
  {
    id: "forest-mist",
    label: "Forest Mist",
    values: {
      primary: "#059669",
      secondary: "#22C55E",
      accent: "#A3E635",
      background: "#5EEAD4"
    }
  },
  {
    id: "lavender-dreams", 
    label: "Lavender Dreams",
    values: {
      primary: "#9333EA",
      secondary: "#8B5CF6",
      accent: "#818CF8",
      background: "#F0ABFC"
    }
  },
  {
    id: "autumn-harvest",
    label: "Autumn Harvest",
    values: {
      primary: "#D97706",
      secondary: "#F97316",
      accent: "#FACC15", 
      background: "#EF4444"
    }
  },
  {
    id: "arctic-frost",
    label: "Arctic Frost",
    values: {
      primary: "#2563EB",
      secondary: "#22D3EE",
      accent: "#CBD5E1",
      background: "#E5E7EB"
    }
  },
];

export const defaultColorPalettes = [
  {
    id: "monochrome-black-gray",
    label: "Monochrome Black & Gray",
    values: {
      primary: "#191919",
      secondary: "#333333",
      accent: "#666666",
      background: "#FFFFFF",
      text: "#000000",
    },
  },
  {
    id: "monochrome-white-gray",
    label: "Monochrome White & Gray",
    values: {
      primary: "#FFFFFF",
      secondary: "#CCCCCC",
      accent: "#999999",
      background: "#191919",
      text: "#FFFFFF",
    },
  },
  {
    id: "classic-blue-gray",
    label: "Classic Blue & Gray",
    values: {
      primary: "#1E3A8A",
      secondary: "#475569",
      accent: "#FACC15",
      background: "#F1F5F9",
      text: "#000000",
    },
  },
  {
    id: "modern-teal-charcoal",
    label: "Modern Teal & Charcoal",
    values: {
      primary: "#00897B",
      secondary: "#37474F",
      accent: "#FF7043",
      background: "#ECEFF1",
      text: "#000000",
    },
  },
  {
    id: "elegant-black-gold",
    label: "Elegant Black & Gold",
    values: {
      primary: "#222831",
      secondary: "#393E46",
      accent: "#FFD369",
      background: "#EEEEEE",
      text: "#000000",
    },
  },
  {
    id: "bold-red-dark-gray",
    label: "Bold Red & Dark Gray",
    values: {
      primary: "#D72638",
      secondary: "#373F51",
      accent: "#F8E16C",
      background: "#F4F4F9",
      text: "#000000",
    },
  },
  {
    id: "navy-soft-green",
    label: "Navy & Soft Green",
    values: {
      primary: "#2C3E50",
      secondary: "#4CAF50",
      accent: "#E67E22",
      background: "#ECF0F1",
      text: "#000000",
    },
  },
  {
    id: "sophisticated-purple-gray",
    label: "Sophisticated Purple & Gray",
    values: {
      primary: "#6A0572",
      secondary: "#8A8D93",
      accent: "#FF5E78",
      background: "#F7F9FC",
      text: "#000000",
    },
  },
  {
    id: "earthy-olive-cream",
    label: "Earthy Olive & Cream",
    values: {
      primary: "#556B2F",
      secondary: "#8F9779",
      accent: "#D2691E",
      background: "#FAF3E0",
      text: "#000000",
    },
  },
  {
    id: "minimalist-white-dark-blue",
    label: "Minimalist White & Dark Blue",
    values: {
      primary: "#1B263B",
      secondary: "#415A77",
      accent: "#FF8C42",
      background: "#E0E1DD",
      text: "#000000",
    },
  },
  {
    id: "warm-beige-navy",
    label: "Warm Beige & Navy",
    values: {
      primary: "#22333B",
      secondary: "#B8B8AA",
      accent: "#D17A22",
      background: "#F5F3F4",
      text: "#000000",
    },
  },
  {
    id: "futuristic-cyan-black",
    label: "Futuristic Cyan & Black",
    values: {
      primary: "#00ADB5",
      secondary: "#393E46",
      accent: "#FF2E63",
      background: "#222831",
      text: "#FFFFFF",
    },
  },
  {
    id: "midnight-blue-bronze",
    label: "Midnight Blue & Bronze",
    values: {
      primary: "#102A43",
      secondary: "#855E42",
      accent: "#FFD700",
      background: "#191919",
      text: "#FFFFFF",
    },
  },
  {
    id: "espresso-burgundy",
    label: "Espresso & Burgundy",
    values: {
      primary: "#4B2C20",
      secondary: "#800020",
      accent: "#E1B07E",
      background: "#1F120D",
      text: "#FFFFFF",
    },
  },
  {
    id: "soft-lavender-peach",
    label: "Soft Lavender & Peach",
    values: {
      primary: "#B565A7",
      secondary: "#F7C5A8",
      accent: "#6D597A",
      background: "#FFF5E1",
      text: "#000000",
    },
  },
  {
    id: "mint-sky-blue",
    label: "Mint & Sky Blue",
    values: {
      primary: "#A8E6CF",
      secondary: "#FFD3B6",
      accent: "#74B3CE",
      background: "#FFFFFF",
      text: "#000000",
    },
  },
];

export const Appearance = ({
  filter = {},
  type = "designs",
  isExpanded = true,
  colorPalettes = defaultColorPalettes,
  payload = {},
  onThemeChange = () => {},
  onColorChange = () => {},
}) => {
  const [templates, setTemplates] = useState([]);
  // const { updateContent } = useEditorActions();

  const fetchTemplates = async () => {
    const items = await getCatalogItems({ type, ...filter });
    console.log({ items });
    setTemplates(
      items.map(({ name, id, image }) => ({
        label: name,
        id,
        icon: (
          <div className="w-12 h-12 mr-2 overflow-hidden rounded">
            <img src={image} className="w-full" />
          </div>
        ),
      })) || []
    );
  };

  const handleTemplateChange = async (key) => {
    const template = templates[key];
    // console.log({ template })
    const id = template.id;

    // get the page content
    const templateData = await getCatalogItem(id);
    console.log({ template, templateData });

    // const { page } = templateData;
    // const { content } = page;
    // const token = getSessionToken();
    // const { content } = await getPage(templateData?.page?.id, { token })

    const pageDetails = await client.query({
      query: GET_PAGE,
      variables: { pageId: templateData?.page?.id },
    });
    const content = get(pageDetails, "data.page.content", false);

    // update the page with the content
    // if (updateContent) updateContent(content, DEFAULT_PAGE_ID);
    // console.log("Changing Content", content);
    onThemeChange(templateData?.id, content);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const colorItems = colorPalettes.map((palette) => ({
    label: palette.label,
    id: palette.id,
    icon: (
      <div className="flex mr-1">
        <div
          className="w-3 h-3 mr-1"
          style={{ backgroundColor: palette.values.primary }}
        />
        <div
          className="w-3 h-3 mr-1"
          style={{ backgroundColor: palette.values.secondary }}
        />
        <div
          className="w-3 h-3 mr-1"
          style={{ backgroundColor: palette.values.accent }}
        />
        <div
          className="w-3 h-3 mr-1"
          style={{ backgroundColor: palette.values.background }}
        />
      </div>
    ),
  }));

  return (
    <SectionWrapper label="Appearance" isExpanded={isExpanded}>
      {/* <DropdownGroup
        label="Theme"
        onChange={handleTemplateChange}
        value={payload?.find((v) => v.id === "themeId")?.value}
        items={templates}
      /> */}
      <DropdownGroup
        label="Colors"
        onChange={(key) => onColorChange(colorPalettes[key]?.id)}
        // value={payload?.find((v) => v.id === "colorPalette")?.value}
        value={payload?.find(v => v.id === "palette")?.value}
        items={colorItems}
      />
    </SectionWrapper>
  );
};
