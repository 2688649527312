import * as React from "react";
import { get } from "lodash";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { updateSection } from "@/Apps/Pages/Properties/components/generator";

// import { fieldset } from "@launchos/components/ui/theme";
import { fieldset } from "@/ui/theme";

import { PropertiesProps, LinkLayoutStyles, LinkDisplayTypes } from "../types";
import linkBuilderSettings from "../settings";

import { LinkBuilder } from "./LinkBuilder";
import { PageContext } from "@/Apps/Pages/Page/PageContext";
import { useContext } from "react";

// import { SectionTypes } from "@/Apps/Pages/Properties/types";
// import { IconTypes } from "../../Icon/types";

// import { ILinks } from "@launchos/modules/v2/CRUD/AttributeBuilder/LinkSelector/types";

/**
 * The property window that shows for the FormBuilderEditorObject
 */
export const Properties: React.FC<PropertiesProps> = (props) => {
  const [settings, setSettings] = React.useState(props.settings);
  const { updateComponentSettings, getCampaignQuery } = props;

  const handleSettingsChange = (id, settings, dbUpdate = true) => {
    setSettings(settings);
    if (dbUpdate) updateComponentSettings(id, settings, true, false);
  };

  const { properties } = linkBuilderSettings;
  const { sections } = properties.main;

  const withLinkLayout = updateSection(sections, "LinkLayout", {
    component: (
      <fieldset style={fieldset}>
        <legend>Layout Style</legend>
        <RadioGroup
          // aria-label="linkDisplay"
          // name="linkDisplay"
          value={settings.layoutStyle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleSettingsChange(settings.id, {
              ...settings,
              layoutStyle: e.target.value as LinkLayoutStyles,
            });
          }}
        >
          <div style={{ display: "flex" }}>
            <FormControlLabel
              value={LinkLayoutStyles.HORIZONTAL}
              control={<Radio />}
              label="Horizontal"
            />
            <FormControlLabel
              value={LinkLayoutStyles.VERTICAL}
              control={<Radio />}
              label="Vertical"
            />
          </div>
        </RadioGroup>
      </fieldset>
    ),
  });

  const withLinkBuilder = updateSection(withLinkLayout, "LinkBuilderSettings", {
    component: (
      <LinkBuilder
        {...settings}
        links={get(getCampaignQuery, "campaign.objects", []).map((object) => ({
          type: object.page.type,
          slug: object.page.slug,
          name: object.name,
        }))}
        onChange={(data) =>
          handleSettingsChange(settings.id, { ...props.settings, data })
        }
      />
    ),
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withLinkBuilder },
  };

  return <PropertiesBuilder data={updatedProperties} {...props} />;
};

export const SimpleNavigationProperties = props => {
  const { completedItems, updateCompletedItems } = useContext(PageContext);
  const [settings, setSettings] = React.useState(props.settings);
  const { updateComponentSettings, getCampaignQuery } = props;

  const handleSettingsChange = (id, settings, dbUpdate = true) => {
    // if the current item is not in the completedItems array, add it
    const isComplete = completedItems.includes(settings.id);
    if (!isComplete) updateCompletedItems([...completedItems, settings.id]);

    setSettings(settings);
    if (dbUpdate) updateComponentSettings(id, settings, true, false);
  };
  
  return (
    <div className="px-3">
      {/* <h3 className="text-sm font-medium">{settings?.prompt}</h3> */}
      <div className="rounded-lg my-5" style={{}}>
        <LinkBuilder
          {...settings}
          linkType={false}
          links={get(getCampaignQuery, "campaign.objects", []).map((object) => ({
            type: object.page.type,
            slug: object.page.slug,
            name: object.name,
          }))}
          onChange={(data) =>
            handleSettingsChange(settings.id, { ...props.settings, data })
          }
        />
      </div>
    </div>
  );
}

export default React.memo(Properties);
// export default LinkBuilderPropertyWindow;
