import { CSSProperties, ReactNode } from "react";
import { IPropertyBuilderSettings } from "@/Apps/Pages/Properties/components/generator/types";
import { IAction } from "@/Apps/Pages/Properties/BuildingBlocks/Interactions/types";

export const CURSOR_ID: string | number = 1111;
export const BODY_ID: string | number = 2540;
export const DEFAULT_PAGE_ID: string | number = "0000";

export interface DropDownItem {
  id: string;
  text?: string;
  icon?: string;
  // onClick?: ({ content, settings }) => void;
  onClick?: ({ content, settings }: { content: any; settings: any }) => void;
}

export enum HoverCursorMethods {
  INSIDE_ITSELF,
  INSIDE_EMPTY_CONTAINER,
  BOTTOM_20_COLUMN,
  BOTTOM_20_OTHER,
  TOP_10_COLUMN,
  TOP_10_OTHER,
  LAST_ITEM_IN_CONTAINER,
  BEFORE_AFTER_MOUSE_POSITION,
}

export enum ComponentTypes {
  SECTION = "Section",
  GRID = "Grid",
  GRIDITEM = "GridItem",
  HEADLINE = "Headline",
  TEXT = "Text",
  PARAGRAPH = "Paragraph", // discontinued (v1)
  VIDEO = "Video",
  IMAGE = "Image",
  CONTAINER = "Container",
  CURSOR = "Cursor",
  STEPPER = "Stepper",
  STEP = "Step",
  GROUP = "Group",
  SPACER = "Spacer",
  DIVIDER = "Divider",
  FORM = "Form",
  FORMFIELD = "Form Field",
  V1FORMFIELD = "FormField",
  NAVIGATION = "Navigation",
  BUTTON = "AdvancedButton",
  V1BUTTON = "Button", // discontinued (v1)
  TABLE = "Table",
  ANIMATION = "Animation",
  LIST = "List",
  LISTITEM = "ListItem",
  ORDERBUMP = "OrderBump",
  POPUP = "Popup",
  PROGRESS = "Progress",
  BLOCK = "Block",
  ICON = "Icon",
  REPEATER = "Repeater",
  ACCORDIAN = "Accordian",
  ACCORDIANITEM = "AccordianItem",
  GOOGLELOGIN = "GoogleLogin",
  FACEBOOKLOGIN = "FacebookLogin",
  COUNTDOWN = "Countdown",
  SHAREBUTTON = "ShareButton",
  ORDERSUMMARY = "OrderSummary",
  SHAPES = "Shapes",
  STACKS = "Stacks",
  SWITCH = "Switch",
  SMARTCONTAINER = "SmartContainer",
  FACEBOOKCOMMENTS = "FacebookComments",
  BODY = "Body",
  HTML = "HTML",
  TESTING = "TESTING",
  COLUMNS = "Columns",
  ROW = "Columns",
  COLUMN = "Column",
  PICTURE = "Picture",
  TABS = "Tabs",
  TAB = "Tab",
  CAROUSEL = "Carousel",
  CAROUSELITEM = "CarouselItem",
  NEWWEBCOMPONENT = "Testing",
  UNKNOWN = "Unknown",
  PAYMENT = "Payment",
  ADDRESS = "Address"
}

export enum IStates {
  NORMAL = "normal",
  ACTIVE = "active",
  HOVER = "hover",
}

export type ISettings = {
  id?: string;
  type: ComponentTypes;
  html?: string;
  canHaveChildren?: boolean;
  preventDelete?: boolean;
  properties: CSSProperties;
  state?: IStates;
  parent?: string;
  fontsUsed?: string[];
  isLoading?: boolean; // see video component
  actions?: IAction[];
};

export interface IComponentSettings {
  id: string;
  name: string;
  category?: "element" | "layout" | "social" | "payment";
  description?: string;
  thumbnail:
  | {
    image?: string;
    style?: CSSProperties;
    icon?: string | ReactNode;
    // iconSource?: IconSource;
    iconStyle?: CSSProperties;
  }
  | string;
  default: ISettings;
  properties?: IPropertyBuilderSettings;
}

export enum EditorMode {
  LIVE = "live",
  EDITOR = "editor",
  SIMPLE = "simple",
}

// to update (see modules/v2/Editor/types in @sandcastle)
export interface EditorComponentProps {
  settings: any
}
