import { getSessionToken } from "@/api/auth";
import { updatePage } from "@/api/pages";
import Button from "@/ui/Button";
import Calout from "@/ui/Calout";
import { Panel, PanelItem } from "@/ui/Panel";
import { ButtonTypes } from "@/ui/types";
import {
  Copy,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";
import { useEffect } from "react";
import slugify from "slugify";

export const ShareURL = ({ unitName = "funnel", campaignData = {}, setCampaignData = () => {} }) => {
  
  const domain = campaignData?.domain?.name;
  const slug = campaignData?.objects[0]?.page?.slug || campaignData?.id;
  const link = `https://${domain}/${slug}`;

  const changeSlug = async () => {
    const newSlug = window.prompt("Enter the new URL slug:", slug);
    if (newSlug) {
      const token = getSessionToken();
      const page = campaignData?.objects[0]?.page;
      const response = await updatePage(page?.id, { token, settings: { slug: slugify(newSlug) } });
      if (response) {
        setCampaignData({
          ...campaignData,
          objects: [
            {
              ...campaignData?.objects[0],
              page: { ...page, slug: slugify(newSlug) },
            },
            ...campaignData?.objects.slice(1),
          ],
        });
        
        alert("Slug updated successfully! Please publish your page to update the URL.");
      } else {
        alert("Failed to update slug. Please try again.");
      }
      console.log("response", response);
    }
  }

  return (
    <Panel title={`Share this ${unitName}`} className="flex-row grid gap-4" actionButton={<Button onClick={changeSlug} type={ButtonTypes.SOFT} label="Change URL (Slug)" />}>
      <PanelItem
        title={
          <p>
            {/* Your Referral Link */}
            Your Link for Sharing
            <br />
            <div className="border border-black/10 dark:border-white/10 rounded-lg p-3 my-2 font-normal flex justify-between items-center bg-white/5">
              {/* https://themeforest.com/reffer/?refid=example */}
              <span className="cursor-pointer" onClick={() => window.open(link)}>{link}</span>
              <Copy
                className="cursor-pointer"
                onClick={async () => {
                  await navigator.clipboard.writeText(link);
                  alert("Copied to clipboard!");
                }}
              />
            </div>
          </p>
        }
        description={`Use this link to publicize your ${unitName.toLowerCase()} and share it with others. This is a unique URL that will direct users to your ${unitName.toLowerCase()}.`}
        // description="Plan your blog post by choosing a topic, creating an outline conduct research, and checking facts."
        // button={<Copy />}
      />
      {/* <div className="border-t border-black/10 dark:border-white/10" />
      <PanelItem
        title="How to use Referral Program"
        description="Use images to enhance your post, improve its flow, add humor and explain complex topics."
        // button={<Link to="#">Get Started</Link>}
        buttonLabel="Get Started"
      />
      <div className="mt-4">
        <Calout
          icon={<CurrencyCircleDollar />}
          title="Withdraw Your Money to a Bank Account"
          caption="Withdraw money securily to your bank account. Commision is $25 per transaction under $50,000"
        />
      </div> */}
    </Panel>
  );
};
