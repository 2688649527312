import { useContext, useEffect, useState } from "react";

import { ComponentTypes } from "@/Apps/Pages/Editor/types";

import { MobileState } from "@/Apps/Pages/MobileResponsiveToggle/types";
import { SwitchGroup } from "@/Apps/Pages/Properties/components/groups/SwitchGroup/SwitchGroup";
import SectionWrapper from "@/Apps/Pages/Properties/components/sections/SectionWrapper";
import {
  changeState,
  listAncestors,
  recursivelyRetrieveItem,
  updateComponentSettings,
} from "@/Apps/Pages/V2Editor/actions";

import { updateComponentStyle } from "@/Apps/Pages/V2Editor/actions";
import { SimpleTextProperties } from "@/Apps/Pages/WebComponents/v1/text/Text";
import { triggerHook } from "@/plugins/client";
import { HookTriggers, ComponentRenderHooks } from "@/plugins/types";
import { PageContext } from "@/Apps/Pages/Page/PageContext";
import { get } from "lodash";
import { EditorObjectState } from "@/Apps/Pages/V2Editor/components/EditorObject/types";

const GroupLabel = ({
  id,
  label,
  count,
  isComplete = false,
  defaultIsExpanded = false,
  handleChangeState,
  children,
}: {
  label: string;
  count: number;
  isComplete?: boolean;
  children: React.ReactNode;
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);

  useEffect(() => {
    setIsExpanded(defaultIsExpanded);
  }, [defaultIsExpanded]);

  return (
    <div 
      className="rounded-lg dark:hover:bg-white/5 hover:bg-black/5 p-3 -ml-3 cursor-pointer"
      onMouseEnter={() => handleChangeState(id, EditorObjectState.HOVER)}
      onMouseLeave={() => handleChangeState(id, EditorObjectState.NORMAL)}
      onClick={() => handleChangeState(id, EditorObjectState.ACTIVE)}
    >
      <h3
        className="text-sm font-medium flex items-center gap-2"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span
          className={`flex items-center justify-center w-5 h-5 rounded-full ${isComplete ? "bg-green-500" : "bg-blue-500"} text-white text-xs`}
        >
          {isComplete ? "✓" : count}
        </span>
        {label}
      </h3>
      {isExpanded && children}
    </div>
  );
};

export const SectionGroup = ({
  section = {},
  content = [],
  defaultIsExpanded = false,
  // setContent = (newContent: any[]) => {},
  // changeState,
  updateEditorContent = () => {},
  updateEditorComponent = () => {},
  updateEditorComponentStyle = () => {},
}) => {
  const [isChecked, setIsChecked] = useState(
    Boolean(section?.properties?.display === "none") ? false : true
  );
  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);
  const { completedItems, setContent, activeSettings } = useContext(PageContext);

  const handleSectionDisplay = () => {
    const newSection = updateComponentStyle(content, section?.id, {
      display: isChecked ? "none" : "block",
    });

    const newContent = updateComponentSettings(content, section?.id, {
      ...newSection,
      properties: {
        ...newSection.properties,
        display: isChecked ? "none" : "block",
      },
    });

    console.log({ newContent });

    setContent(newContent);

    setIsChecked(!isChecked);
  };

  const handleChangeState = (id, state: EditorObjectState) => {
    const newContent = changeState(content, id, state, () => {});
    if (newContent) setContent(newContent);
  };

  const sectionChildren = recursivelyRetrieveItem(content, section?.id);

  useEffect(() => {
    setIsExpanded(defaultIsExpanded);
  }, [defaultIsExpanded]);

  return (
    <div
      onMouseEnter={() =>
        handleChangeState(section?.id, EditorObjectState.HOVER)
      }
      onMouseLeave={() =>
        handleChangeState(section?.id, EditorObjectState.NORMAL)
      }
      onClick={() => {
        handleChangeState(section?.id, EditorObjectState.ACTIVE);

        // scroll to the section
        document.getElementById(`section_${section?.id}`)?.scrollIntoView({
          behavior: "smooth",
        });
      }}
    >
      <SectionWrapper
        label={`${section?.label} Section` || `SECTION ${index + 1}`}
        isExpanded={isExpanded}
      >
        {/* <div className="border border-black/10 dark:border-white/10 px-5 rounded-lg"> */}
        <div>
          <SwitchGroup
            label="Display this section"
            isChecked={isChecked}
            onClick={handleSectionDisplay}
          >
            <p>Show or hide this section.</p>
          </SwitchGroup>

          {/* display children */}
          {sectionChildren && (
            <div
              className="max-h-96 overflow-auto"
              style={{ display: isChecked ? "block" : "none" }}
            >
              {sectionChildren
                .filter((itm) => itm.prompt)
                .map((itm: any, i: number) => {
                  // if (!itm?.prompt) return null;

                  const isComplete = completedItems.includes(itm.id);

                  return (
                    <div className="px-3" key={i}>
                      <GroupLabel
                        label={itm?.prompt}
                        count={i + 1}
                        isComplete={isComplete}
                        handleChangeState={handleChangeState}
                        id={itm?.id}
                        defaultIsExpanded={activeSettings?.id === itm?.id}
                      >
                        {triggerHook(
                          HookTriggers.onComponentRender,
                          {
                            id: ComponentRenderHooks.SIMPLE_PROPERTIES,
                            type: itm?.type,
                          },
                          {
                            settings: itm,
                            getSettings: () => itm,
                            rSettings: itm,
                            updateComponentStyle: updateEditorComponentStyle,
                            updateContent: updateEditorContent,
                            updateComponentSettings: updateEditorComponent,
                            setMobileState: () => {},
                            getMobileState: () => MobileState.FULLSCREEN,
                            currentMobileState: MobileState.FULLSCREEN,
                          }
                        )}
                      </GroupLabel>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </SectionWrapper>
    </div>
  );
};

export const SimpleProperties = ({
  campaignData = {},
  pageId,
  content = [],
  setCampaignData = () => {},
  setIsLoading = () => {},
  setContent = () => {},
  updateEditorContent = () => {},
  updateEditorComponent = () => {},
  updateEditorComponentStyle = () => {},
}) => {
  // const { activeContent = [], setContent = () => {} } = useContext(PageContext);
  const { activeSettings = {} } = useContext(PageContext);
  const ancestors = activeSettings ? listAncestors(content, activeSettings?.id) : [];

  // console.log({ ancestors });

  return (
    <div className="w-full h-full divide-y divide-black/10 dark:divide-white/10 overflow-y-auto">
      {/* <SwitchGroup
        label="Enable this page"
        isChecked={true}
        // onClick={() => setIsChecked(!isChecked)}
        className="p-5"
      >
        <p>Display this page or hide it and redirect to your website.</p>
      </SwitchGroup> */}

      {content
        ?.filter((itm) => itm?.type === ComponentTypes.SECTION)
        .map((section: any, index) => (
          <SectionGroup
            key={index}
            section={section}
            content={content}
            defaultIsExpanded={ancestors?.find(ancstr => ancstr.type === ComponentTypes.SECTION)?.id === section.id}
            // defaultIsExpanded={ancestors?.find(ancstr => ancstr.type === ComponentTypes.SECTION)?.id === section.id || index === 0}
            campaignData={campaignData}
            setCampaignData={setCampaignData}
            setContent={setContent}
            updateEditorContent={updateEditorContent}
            updateEditorComponent={updateEditorComponent}
            updateEditorComponentStyle={updateEditorComponentStyle}
          />
        ))}

      {/* Render Widgets */}
      {/* {widgets.map((widget, key) => {
            return (
              <span key={key} className="">
                {renderWidget(widget, {
                  campaignId: campaignData?.id,
                  pageId: campaignData?.objects[0].page?.id,
                  updateContent: console.log,
                  updateComponent: () => {},
                })}
              </span>
            );
          })} */}
    </div>
  );
};
