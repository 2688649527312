import * as React from "react";
import WebFont from "webfontloader";
import { uniq } from "lodash";

import { debounce } from "lodash";
import { Editor } from "@tinymce/tinymce-react";

import { RichEditorProps } from "./types";
import { getInitValues, apiKey } from "./initValues";
import GoogleFontSelector from "./GoogleFontSelector";

import "./index.css";
import { listAncestors } from "@/Apps/xEditorCanvas/actions";
import { PageContext } from "../../Page/PageContext";
import { useContext } from "react";
import { MobileState } from "../../MobileResponsiveToggle/types";
import { getResponsiveSettings } from "../../Page/scripts";
import { parseLiquidObject } from "../../Page/LiquidRenderer";

// wrap the editor with the background of the ancestors
// so that the text looks the way it does in the canvas
export const wrapWithAncestorBackgrounds = (
  children: any,
  id: string,
  consoleLog = false
) => {
  // const id = settings?.id;

  // get content
  const { activeContent = [], mobileState = MobileState.FULLSCREEN } =
    useContext(PageContext);

  // get ancestors of the component
  const ancestors = listAncestors(activeContent, id);
  // get properties of the ancestors
  const ancestorProperties =
    ancestors?.map((settings) => {
      const rSettings = parseLiquidObject(getResponsiveSettings(settings, { type: mobileState }));
      // if (consoleLog && settings.id === "1h7iuad9kvm")
      //   console.log("rSettings", rSettings, { settings, mobileState });
      return rSettings.properties;
    }) || [];

  // now merge the properties, prioritizing the last to first
  const mergedProperties = ancestorProperties.reduceRight((acc, properties) => {
    return {
      ...acc,
      ...properties,
    };
  }, {});

  if (consoleLog && id === "7buy5cg72yc")
    console.log({ ancestors, ancestorProperties, mergedProperties });

  return (
    <div
      style={{
        zoom: "75%",
        backgroundColor: mergedProperties?.backgroundColor || 'white',
        // backgroundColor: 'white',
        backgroundImage: mergedProperties?.backgroundImage
          ? `url(${mergedProperties.backgroundImage})`
          : "none",
        backgroundSize: mergedProperties?.backgroundSize || "cover",
        backgroundPosition: mergedProperties?.backgroundPosition || "center",
        backgroundRepeat: mergedProperties?.backgroundRepeat || "no-repeat",
        // padding: "10px"
      }}
    >
      {children}
    </div>
  );
};

/**
 * A component for providing rich, wysiwyg text composition.
 * (A wrapper of the Tiny MCE library)
 */
const RichEditor: React.FC<RichEditorProps> = (props) => {
  const {
    id,
    content = "",
    disabled = false,
    ref,
    onClick = () => null,
    onMouseEnter = () => null,
    onChange = () => null,
    onInstantChange = () => null,
  } = props;

  const [fontsUsed, setFontsUsed] = React.useState<string[]>([]);
  const [isGoogleFontSelectorOpen, setIsGoogleFontSelectorOpen] =
    React.useState<boolean>(false);
  const [activeEditor, setActiveEditor] = React.useState<any>();
  const [html, setHTML] = React.useState<string>(content);
  const [didChange, setDidChange] = React.useState<boolean>(false);
  const [doDebounce] = React.useState(() =>
    debounce(() => setDidChange(true), 1000)
  );

  React.useEffect(() => {
    setHTML(content);
  }, [content]);

  // const debouncedOnChange = React.useCallback(
  //   debounce((html: string, fonts: string[]) => {
  //     onChange(html, fonts);
  //   }, 1000),
  //   [onChange]
  // );
  React.useEffect(() => {
    // console.log("Content about to be changed", html, content);
    if (html !== content) {
      // don't trigger if content is the same
      onChange(html, fontsUsed);
    }
    setDidChange(false);
  }, [didChange]);

  // const handleOnChange: (newContent: string) => void = (newContent) => {
  //   if (html !== newContent) {
  //     console.log("RichEditor handleOnChange", html);
  //     setHTML(newContent);
  //     onInstantChange(newContent, fontsUsed);
  //     debouncedOnChange(newContent, fontsUsed);
  //   }
  // };
  const handleOnChange: (html: string) => void = (html) => {
    // console.log("Trying to change!", html);
    setHTML(html);
    onInstantChange(html, fontsUsed);
    doDebounce();
  };

  React.useEffect(() => {
    // if the text content is updated externally (from the props) (not working!)
    setHTML(content);
    // onChange(content)
    setDidChange(true);
    // console.log("Content to be updated from external trigger", content);
    doDebounce();
  }, [content]);

  React.useEffect(() => {
    setDidChange(true);
  }, []);

  const openGoogleFontSelector: (editor: any) => void = (editor) => {
    setIsGoogleFontSelectorOpen(true);
    setActiveEditor(editor);
  };

  const handleGoogleFontSelection = (font, callbackFn = () => null) => {
    WebFont.load({
      google: {
        families: [font],
      },
      active: () => {
        callbackFn();
        activeEditor.execCommand("FontName", false, font);
        setIsGoogleFontSelectorOpen(false);
        setFontsUsed(uniq([...fontsUsed, font]));
      },
    });
  };

  const initialValues = getInitValues({ ...props, openGoogleFontSelector });

  const isSafari =
    typeof window === "object"
      ? /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      : false;

  return (
    <div
      id={id}
      data-testid="Editor-Components-RichEditor"
      data-placeholder="Click here to enter your content..."
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      onMouseEnter={() => {
        if (isSafari) {
          // Fixes Safari issue where TinyMCE doesn't allow editing inside of a <div draggable="true" /> container
          document
            .querySelectorAll("[draggable]")
            .forEach((el) => el.setAttribute("draggable", "false"));
        }
        onMouseEnter();
      }}
      onMouseLeave={() => {
        if (isSafari) {
          // Fixes Safari issue where TinyMCE doesn't allow editing inside of a <div draggable="true" /> container
          // ...but draggability ceases to work <- To Fix!
          document
            .querySelectorAll("[draggable]")
            .forEach((el) => el.setAttribute("draggable", "true"));
        }
      }}
      ref={ref}
    >
      <GoogleFontSelector
        open={isGoogleFontSelectorOpen}
        onClose={() => setIsGoogleFontSelectorOpen(false)}
        onSelect={handleGoogleFontSelection}
      />
      {/* {JSON.stringify({ disabled })} */}
      <Editor
        // apiKey={apiKey}
        tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js"
        value={html}
        init={initialValues}
        onEditorChange={handleOnChange}
        disabled={Boolean(disabled)}
      />
    </div>
  );
};

export default RichEditor;
