import { DocumentNode, gql } from "@apollo/client";

export const GET_PAGE_CAMPAIGNS: DocumentNode = gql`
  query getPageCampaigns($userId: ID!) {
    campaigns(where: { type: "page", user: { id: $userId } }) {
      id
      name
      objects {
        id
        name
        page {
          id
          slug
        }
      }
    }
  }
`;

// getPages;
export const GET_PAGE: DocumentNode = gql`
  query getPage($pageId: ID!) {
    page(where: { id: $pageId }) {
      id
      name
      content
      publishedAt
      pageSettings
      slug
      type
      homePageForDomain {
        id
      }
      errorPageForDomain {
        id
      }
      object {
        id
        connectTo {
          id
          deleted
          page {
            id
            slug
          }
        }
      }
    }
  }
`;
