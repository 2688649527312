import { get } from "lodash";
import { Component, useEffect } from "react";
import { WebComponent } from "../WebComponent/WebComponent";
import { getInputVals, getResponsiveSettings, sendData } from "./scripts";
// import { triggerHook } from "@/plugins/live";
import { triggerHook } from "@/plugins/live";
import { ComponentRenderHooks, HookTriggers } from "@/plugins/types";
import { generateFontList } from "../utils";
import { MobileState } from "../MobileResponsiveToggle/types";
import { beforeContentLoads } from "./scripts/preProcessContent";
import { EditorMode } from "../Editor/types";

// import WebFontLoader from "webfontloader";
import { PageState, PageProps } from "./types";
import { PageContext } from "./PageContext";
import { StripeElementWrapper } from "../WebComponents/v2/PaymentElement/live";

export class PageContent extends Component<PageProps, PageState> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      content: JSON.parse(unescape(get(props, "content", "[]"))),
      currentMobileState: { type: MobileState.FULLSCREEN },
    };

    this.generatePage = this.generatePage.bind(this);
    this.updateComponentSettings = this.updateComponentSettings.bind(this);
    this.updateComponentStyle = this.updateComponentStyle.bind(this);
    this.getComponentSettings = this.getComponentSettings.bind(this);
  }

  async fetchContent(id: string, callbackFn = () => null) {
    // const isBrowser = typeof window !== "undefined";
    // if (!isBrowser) return null;

    const localSubdomain = `cWEtdGVzdC1reWxlLTA1LnRlbm1pbnV0ZWZ1bm5lbHMuY29t`;
    // const localSubdomain = `bGF1bmNob3MudGVubWludXRlZnVubmVscy5jb20=` // launchos.tenminutefunnels.com
    // const localSubdomain = "ZnJlZWRvbXNvZnQudGVubWludXRlZnVubmVscy5jb20="; // freedomsoft.tenminutefunnels.com

    const ztx = atob;

    let host =
      window.location.hostname === "localhost"
        ? `sandcastlesites.s3-website-us-east-1.amazonaws.com/${ztx(localSubdomain)}`
        : window.location.hostname;

    // if (window?.scriptUrl) host = `sandcastlesites.s3.amazonaws.com/${new URL(window?.scriptUrl).hostname}`;
    
    if (window?.scriptUrl) {
      host = new URL(window?.scriptUrl).hostname;
      console.log("host from Page is", host);
    }

    const protocol = window.location.protocol;
    const url = `${protocol}//${host}/pages/${id}.json`;
    // const url = `sandcastlesites.s3-website-us-east-1.amazonaws.com/${ztx(localSubdomain)}`;

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        // Origin: window.location.origin,
      },
    });

    const result = await response.json();

    // Pre-process the content before it loads.
    const content = await beforeContentLoads({
      pageId: id,
      content: result,
      nextPage: this.props.nextPage,
      getPageData: this.props.getPageData,
      trackingPayload: JSON.parse(this.props.trackingPayload || "{}"),
    });

    this.setState({ content }, callbackFn);
  }

  async componentDidMount() {
    const loadFonts = () => {
      // load fonts
      const fonts = generateFontList(this.state.content);

      if (fonts.length) {
        // WebFontLoader.load({
        //   google: {
        //     families: fonts,
        //   },
        // });
      }
    };
    
    // -> if content is empty, fetch it from s3 <-
    if (!this.state.content.length || !this.state.content) {
      this.fetchContent(this.props.id);
    } else {
      loadFonts();
    }
  }

  updateComponentStyle(id, newStyle, callbackFn = () => false) {
    const { content } = this.state;

    const key = content.findIndex((itm) => itm.id === id);
    const settings = content[key];

    const updatedSettings = {
      ...settings,
      properties: {
        ...settings.properties,
        ...newStyle,
      },
    };

    this.updateComponentSettings(id, updatedSettings, callbackFn);
  }

  updateComponentSettings(id, settings, callbackFn = () => false) {
    const { content } = this.state;
    const key = content.findIndex((itm) => itm.id === id);

    this.setState(
      {
        content: [
          ...content.slice(0, key),
          settings,
          ...content.slice(key + 1),
        ],
      },
      callbackFn
    );
  }

  getComponentSettings(id) {
    const { content } = this.state;
    const key = content.findIndex((itm) => itm.id === id);
    return content[key];
  }

  generatePage(content = [], parent = false) {
    const { currentMobileState } = this.state;
    const node = [];
    let i = 0;
    content
      .filter((c) => c.parent === parent)
      .forEach((c) => {
        i += 1;
        const props = {
          settings: getResponsiveSettings(c, currentMobileState),
          sendData,
          updateComponentSettings: this.updateComponentSettings,
          updateComponentStyle: this.updateComponentStyle,
          getComponentSettings: this.getComponentSettings,
          pageContent: this.state.content,
          pageId: this.props.id,
        };

        const internal = c.html || this.generatePage(content, c.id);
        node[i] = (
          <WebComponent
            {...props}
            key={c.id}
            type={c.type}
            getMobileState={() => currentMobileState}
            mode={EditorMode.LIVE}
            triggerHook={triggerHook}
          >
            {internal}
          </WebComponent>
        );
      });

    return node;
  }

  render() {
    const {
      nextPage = "",
      seoPayload = {},
      merchantPayload = {},
      trackingPayload = {},
      emailPayload = {},
      cssPayload = "",
      exitPayload = {},
      id,
      objectId = "",
      campaignId = "",
      userId = "",
    } = this.props;

    return (
      <>
        {triggerHook(
          HookTriggers.onComponentRender,
          { id: ComponentRenderHooks.BODY_START },
          { trackingPayload }
        )}

        {this.state.content && this.generatePage(this.state.content)}

        {triggerHook(
          HookTriggers.onComponentRender,
          {
            id: ComponentRenderHooks.PAGE_LOAD,
          },
          {
            pageId: id,
            objectId,
            campaignId,
            userId,
            cssPayload,
            exitPayload,
            seoPayload,
            trackingPayload,
            nextPage,
            merchantPayload,
            emailPayload,
            pageContent: this.state.content,
          },
          {
            getComponentSettings: this.getComponentSettings,
            updateComponentSettings: this.updateComponentSettings,
            updateComponentStyle: this.updateComponentStyle,
            setMobileState: (newState) =>
              this.setState({ currentMobileState: newState }),
          }
        )}

        {triggerHook(
          HookTriggers.onComponentRender,
          { id: ComponentRenderHooks.BODY_END },
          { trackingPayload }
        )}
      </>
    );
  }
}

export const Page = (props) => (
  <PageContext.Provider value={{...props, parseLiquid: true}}>
    <StripeElementWrapper>
      <PageContent {...props} />
    </StripeElementWrapper>
  </PageContext.Provider>
);

export default Page;
