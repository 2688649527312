import * as React from "react";
import _, { get } from "lodash";

// import EditorObject from "@/Apps/Pages/EditorObject";
// import PropertiesBuilder from "@launchos/modules/v2/Properties/components/generator";
// import { updateSection } from "@launchos/modules/v2/Properties/components/generator";

// import EditorObject from "@/Apps/Pages/EditorObject"; // Uses v1 i think
import EditorObject from "@/Apps/Pages/EditorObject";
import PropertiesBuilder from "@/Apps/Pages/Properties/components/generator/Generator";
import { updateSection } from "@/Apps/Pages/Properties/components/generator";

import { Button } from "./live";
import { PluginProps } from "./types";
import { V1ObjectWrapper, convertProperties } from "../V1ObjectWrapper";
import buttonSettings from "./settings";
// import { SectionTypes } from "@launchos/modules/v2/Properties/types";
// import { IconTypes } from "../Icon";

import { ColorPickerSection } from "./ColorPickerSection";
import { CaptionSection } from "./CaptionSection";
import { IconTypes } from "../Icon/types";
import { SectionTypes } from "@/Apps/Pages/Properties/types";
import { Caption } from "@/Apps/Pages/Properties";
import { PageContext } from "@/Apps/Pages/Page/PageContext";
import { useContext, useState } from "react";
import { Payload } from "@/Apps/Pages/Properties/BuildingBlocks/Interactions/Payload";
import { ForwardParams } from "@/Apps/Pages/Properties/BuildingBlocks/Interactions/Payloads/GoToUrl";
import { SubmitForm } from "@/Apps/Pages/Properties/BuildingBlocks/Interactions/Payloads/SubmitForm";
export const Properties = (props) => {
  const { updateComponentSettings, settings } = props;
  const { properties } = buttonSettings;
  const { sections } = properties.main;

  const withIcon = updateSection(sections, SectionTypes.ICONSELECTOR, {
    onChange: (e) => {
      console.log(e);
      updateComponentSettings(
        settings.id,
        { ...settings, icon: IconTypes[e] },
        true,
        0
      );
    },
  });

  const withCaption = updateSection(withIcon, "custom_caption", {
    component: <CaptionSection {...props} />,
  });

  const withColorPicker = updateSection(withCaption, "custom_color_picker", {
    component: <ColorPickerSection {...props} />,
  });

  const updatedProperties = {
    ...properties,
    main: { ...properties.main, sections: withColorPicker },
  };

  return (
    <PropertiesBuilder
      title="Button Settings"
      data={updatedProperties}
      {...props}
      children={null}
    />
  );
};

export const SimpleButtonProperties = (props) => {
  const { completedItems, updateCompletedItems } = useContext(PageContext);
  // const [ action, setAction ] = useState(props?.settings?.actions[0]);
  const [liveSettings, setLiveSettings] = useState(props?.settings);
  const { updateComponentSettings, settings } = props;

  const action = liveSettings?.actions[0];

  return (
    <div className="px-3">
      <Caption
        text="Line 1 Text"
        value={get(settings, "caption1", "")}
        placeholder="Enter a label for the first line of the button"
        onKeyUp={(caption1) => {
          updateComponentSettings(
            settings.id,
            {
              ...settings,
              caption1,
            },
            false,
            true
          );
        }}
        onChange={(caption1) => {
          // if the current item is not in the completedItems array, add it
          const isComplete = completedItems.includes(settings.id);
          if (!isComplete)
            updateCompletedItems([...completedItems, settings.id]);

          setLiveSettings({
            ...liveSettings,
            caption1,
          });

          updateComponentSettings(settings.id, {
            ...settings,
            caption1,
          });
        }}
      />
      <Payload
        {...action}
        action={action}
        showForwardParams={false}
        setAction={(key, action, behavior, newSettings = {}) => {

          const newActions = [
            ...props?.settings?.actions.slice(0, key),
            {
              ...props?.settings?.actions[key],
              ...action,
              behavior,
              payload: _.get(action, "payload", false),
            },
            ...props?.settings?.actions.slice(key + 1),
          ];

          console.log("newActions", newActions);

          const updatedSettings = {
            ...settings,
            actions: newActions,
            ...newSettings,
          };

          setLiveSettings(updatedSettings);

          updateComponentSettings(settings.id, updatedSettings);

        }}
      />
      {/* {JSON.stringify(props?.settings?.actions[0])} */}
    </div>
  );
}

/**
 * A button
 */
export const EditorButton: React.FC<PluginProps> = (props) => {
  const { id, mode, settings } = props;
  const properties = convertProperties(settings.properties);

  return (
    <V1ObjectWrapper settings={settings} showOverlay={true}>
      <EditorObject
        // showOverlay
        label="Button"
        id={id}
        {...props}
        PropertiesView={Properties}
      >
        <Button {...settings} properties={properties} />
      </EditorObject>
    </V1ObjectWrapper>
  );
};

export default EditorButton;
