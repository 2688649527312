import _ from "lodash";

import { ForwardParams, GoToUrl } from "./Payloads/GoToUrl";
import { ScrollToSection } from "./Payloads/ScrollToSection";
import { SubmitForm } from "./Payloads/SubmitForm";
import { DownloadFile } from "./Payloads/DownloadFile";
import { TriggerWebHook } from "./Payloads/TriggerWebHook";
import { ShowHide } from "./Payloads/ShowHide";
import { CreateTag } from "./Payloads/CreateTag";
import { PopupOptions } from "./Payloads/PopupOptions";
import { TriggerPurchase } from "./Payloads/TriggerPurchase";
import { TriggerUpsell } from "./Payloads/TriggerUpsell";
import { ToggleAddRemoveCart } from "./Payloads/ToggleAddRemoveCart";
import { SwitchTabs } from "./Payloads/SwitchTabs";
import { AddToCalendar } from "./Payloads/AddToCalendar";

import { ActionTypes } from './types'
import { decode } from "@/Apps/Pages/utils";
import { useContext, useEffect, useState } from "react";
import { getCampaign } from "@/api/campaigns";
import { getSessionToken } from "@/api/auth";
import { useParams } from "react-router-dom";
import { EditorContext } from "@/Apps/Pages/Editor/EditorContext";
import { EditorMode } from "@/Apps/Pages/Editor/types";

export const Payload = (props) => {
    const [campaignData, setCampaignData] = useState({})

    const updateAction = (action, payload, newSettings = {}) => {
        console.log("updateAction", action, payload, newSettings);
        const { setAction, myKey } = props;
        setAction(myKey || 0, { ...action, payload }, action.behavior, newSettings);
        document.querySelector(".MuiMenu-paper").remove(); // closes the dropdown menu
    }

    const {
        type = ActionTypes.DO_NOTHING,
        action = { payload: {} },
        // match = { params: {} },
        getPageQuery = {},
    } = props;

    // const { campaignId = "", nodeId = "" } = match?.params;
    const { campaignId, content, objectId = "", mode, pageCampaigns } = useContext(EditorContext);
    const pageSettings = getPageQuery?.page?.pageSettings;

    const storedEmailPayload = _.get(pageSettings, "email.payload", false);
    const emailPayload = storedEmailPayload ? JSON.parse(decode(storedEmailPayload)) : false;

    useEffect(() => {
        (async () => {

            if (mode === EditorMode.SIMPLE) {
                setCampaignData(pageCampaigns);
            } else {
                const token = getSessionToken();
                const campaignData = await getCampaign({ token, id: campaignId })
                setCampaignData(campaignData);
            }
            console.log("Payload campaign data", campaignData);
        })()
    }, [])

    switch (type) {

        case ActionTypes.GO_TO_URL:
            return <GoToUrl action={action} campaignData={campaignData} updateAction={updateAction} {...props} />

        case ActionTypes.TRIGGER_NEXT_NODE:
            return (
                <ForwardParams action={action} updateAction={updateAction} payload={_.get(action, 'payload')} />
            )
        case ActionTypes.SCROLL_TO_SECTION:
            return <ScrollToSection action={action} updateAction={updateAction} {...props} />

        case ActionTypes.SUBMIT_FORM:
            return <SubmitForm action={action} updateAction={updateAction} campaignData={campaignData} emailPayload={emailPayload} {...props} />

        case ActionTypes.DOWNLOAD_FILE:
            return <DownloadFile action={action} updateAction={updateAction} {...props} />

        case ActionTypes.TRIGGER_WEBHOOK:
            return <TriggerWebHook action={action} updateAction={updateAction} {...props} />

        case ActionTypes.OPEN_POPUP:
            return <PopupOptions {...props} pageContent={content} action={action} updateAction={updateAction} />

        case ActionTypes.TRIGGER_PURCHASE:
            return <TriggerPurchase campaignId={campaignId} nodeId={objectId} />

        case ActionTypes.TRIGGER_UPSELL:
            return <TriggerUpsell campaignId={campaignId} nodeId={objectId} />

        case ActionTypes.SHOW_AND_HIDE:
            return <ShowHide {...props} action={action} updateAction={updateAction} />

        case ActionTypes.CREATE_TAG:
            return <CreateTag {...props} action={action} updateAction={updateAction} />

        case ActionTypes.TOGGLE_ADD_REMOVE_TO_CART:
            return <ToggleAddRemoveCart {...props} action={action} updateAction={updateAction} />

        case ActionTypes.SWITCH_TABS:
            return <SwitchTabs {...props} action={action} updateAction={updateAction} />

        case ActionTypes.ADD_TO_CALENDAR:
            return <AddToCalendar {...props} action={action} updateAction={updateAction} />

        default:
            return null;
    }
}


