import { createContext } from "react";
import { ISettings } from "../Editor/types";
import { MobileState } from "../MobileResponsiveToggle/types";

interface PageContextType {
  activeContent?: ISettings[];
  nextPage?: string;
  seoPayload?: Record<string, unknown>;
  merchantPayload?: Record<string, unknown>;
  trackingPayload?: Record<string, unknown>;
  emailPayload?: Record<string, unknown>;
  cssPayload?: string;
  exitPayload?: Record<string, unknown>;
  id?: string;
  objectId?: string;
  campaignId?: string;
  userId?: string;
  campaignSettings?: Record<string, unknown>;
  completedItems?: string[];
  mobileState?: MobileState;
  setMobileState?: (mobileState: MobileState) => void;
  updateCompletedItems?: (items: string[]) => void;
  setContent: (content: ISettings[]) => void;
}

export const PageContext = createContext<PageContextType>({
  activeContent: [],
  nextPage: "",
  seoPayload: {},
  merchantPayload: {},
  trackingPayload: {},
  emailPayload: {},
  cssPayload: "",
  exitPayload: {},
  id: "",
  objectId: "",
  campaignId: "",
  campaignSettings: {},
  userId: "",
  setContent: () => {},
  mobileState: MobileState.FULLSCREEN,
  setMobileState: () => {},
  completedItems: [],
  updateCompletedItems: () => {},
});
