import * as React from "react";
// import { get } from "lodash";
// import { EmptyState } from "@launchos/modules/editor/Builder/EditorObject/EmptyState";

// import EditorObject from "@launchos/modules/editor/Builder/EditorObject";

import {
  Background,
  WidthHeight,
  PositionPadding,
  Alignment,
  BordersShadow,
  Interactions,
  PropertiesWindow,
  RichEditor,
} from "@/Apps/Pages/Properties";

import { ContainerContent } from "./component";
import V1ObjectWrapper, { convertProperties } from "../../v2/V1ObjectWrapper";
// import { ComponentTypes, BODY_ID } from "@launchos/modules/editor/Builder/WebComponent/types";

// import { EditorMode } from "@launchos/modules/v2/Editor/types";

import EditorObject, { EmptyState } from "@/Apps/Pages/EditorObject";
import { BODY_ID, ComponentTypes, EditorMode } from "@/Apps/Pages/Editor/types";
import { RichEditorPosition } from "@/Apps/Pages/Properties/RichEditor";

const ContainerEdit: React.FC = (props) => {
  const properties = props.settings.properties;

  return (
    <EditorObject
      {...props}
      style={{
        height: "100%",
        display: "block",
      }}
      PropertiesView={Properties}
      color="#AAA"
      // dropDownItems={[...(props.settings.parent === 2540) ? [
      dropDownItems={(items) => {
        // const pasteKey = items.findIndex(({ id }) => id === "PASTE");
        // const withPaste = [
        //   ...items.slice(0, pasteKey),
        //   ...(props.pageContent.findIndex(itm => itm.parent === props.settings.id) === -1) ? [
        //     {
        //       id: "paste_inside",
        //       text: "Paste Inside",
        //       icon: "content_paste"
        //     }
        //   ] : [],
        //   ...items.slice(pasteKey),
        // ]
        const withPaste = items;

        const key = withPaste.findIndex(({ id }) => id === "divider");

        if (location.hostname === "localhost")
          return [
            ...withPaste.slice(0, key),
            { id: "divider" },
            ...(properties.width === "100%"
              ? [
                  {
                    id: "convert",
                    text: "Convert to Section",
                    icon: "view_agenda",
                  },
                  { id: "divider" },
                ]
              : []),
            ...withPaste.slice(key + 1),
          ];
        else return items;
      }}
      onDropDownChange={(id) => {
        if (id === "convert") {
          const label = window.prompt(
            `What type of Section is this? 
            (e.g. Header, Benefit, CTA, Content, Pricing, SocialProof, Form, Navigation, Footer, Guarantee, Quiz, Urgency)`
          );
          props.updateComponentSettings(
            props.settings.id,
            {
              ...props.settings,
              type: ComponentTypes.SECTION,
              label,
              parent: BODY_ID,
            },
            true,
            false
          );
        }

        if (id === "paste_inside") {
          // grab from local storage
          const clipboard = JSON.parse(localStorage.getItem("clipboard"));
          // add to canvas below this section
          props.changeState(2540, "active", () => {
            props.addThisAfterThat(clipboard, props.settings.id, "inside");
          });
        }
        return true;
      }}
    >
      <ContainerContent
        {...props}
        mode={EditorMode.EDITOR}
        EmptyState={EmptyState}
      />
    </EditorObject>
  );
};

const BasicPropertiesTab: React.FC = (props) => (
  <>
    <Background
      {...props}
      accept="image/jpeg, image/png, video/mp4, video/ogg"
      showAlpha
      isExpanded
    />
    <WidthHeight {...props} />
    <PositionPadding {...props} />
    <Alignment {...props} verticalAlign={false} marginAlign />
  </>
);

const AdvancedPropertiesTab: React.FC = (props) => (
  <>
    <BordersShadow {...props} isExpanded />
    <Interactions label="Container Actions" {...props}>
      <div />
    </Interactions>
  </>
);

export const Properties: React.FC = (props) => (
  <PropertiesWindow
    {...props}
    defaultTab="basic"
    tabs={[
      { id: "basic", title: "Container", component: BasicPropertiesTab },
      { id: "advanced", title: "Advanced", component: AdvancedPropertiesTab },
    ]}
  />
);

export default (props) => {
  let settings = props.settings;
  settings.properties.maxWidth = settings.properties.width;
  settings.properties.width = "100%";

  return (
    <V1ObjectWrapper settings={settings}>
      <ContainerEdit {...props} settings={settings} />
    </V1ObjectWrapper>
  );
};
