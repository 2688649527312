import { get, head, isEmpty } from 'lodash'

const parseTemplate = (template, data) => {
  if (!template) return template;
  if (typeof template.replace !== "function") return template;

  return template.replace(/{(\w+\.\w+)}/g, (match, path) => {
    const keys = path.split(".");
    let result = data;

    for (const key of keys) {
      result = result[key];
    }

    return result || match;
  });

}

export const getInputVals = () => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;

  const inputs = document.getElementsByTagName("input");
  const selects = document.getElementsByTagName("select");
  const selectArray = Array.prototype.slice.call(selects);
  const selectVals = selectArray.map(input => ({ name: input.name, value: input.value, id: input.id }));
  const inputArray = Array.prototype.slice.call(inputs);
  const inputVals = inputArray.map(input => ({ name: input.name, value: input.value, id: input.id }));
  return [...inputVals, ...selectVals];
};

export const getInputValue = (name = '', data = {}) => {
  if (data.findIndex(itm => itm.name === name) > -1) {
    return get(head(data.filter(itm => itm.name === name && itm.value !== "")), 'value', '')
  }
  return false;
}

export const deDupInputVals = (inputVals) => {
  let deDupedValues = []

  inputVals.forEach((itm) => {
    const { name } = itm;
    const filtered = inputVals.filter(i => i.name === name)
    if (filtered.length > 1) {
      const toUse = filtered.filter(({ value }) => !isEmpty(value) && value !== 'false')
      if (toUse.length) {
        deDupedValues.push(head(toUse))
      }
      else deDupedValues.push(itm)
    }
    else deDupedValues.push(itm)
  })

  return deDupedValues
}

export const parseValue = (value) => {
  
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) return null;
  
  // convert {url.xyz} into
  const inputVals = getInputVals();
  
  // let dataObject = { url: { firstName: "Kyle", lastName: "Graham" }, form: { middleName: "Jason" } };
  let dataObject = { url: {}, form: {}, contact: {} }; 
  
  // get the URL search parameters
  let params = new URLSearchParams(location.search) 
  
  // add each search parameter to the 'url' object in the 'obj' object
  params.forEach((val, key) => dataObject['url'][key] = val) 
  
  // add each input value to the 'form' object in the 'obj' object
  inputVals.forEach(({ name, value }) => dataObject['form'][name] = value); 
  
  // add any collection data items to the 'contact' object
  dataObject['contact'] = localStorage.getItem('collectionData') ? JSON.parse(localStorage.getItem('collectionData')) : {}
  
  // log a message to the console
  // console.log(`Want to convert ${value} and passing`, dataObject, `through a template parser`)
  
  // let value = "Hello {url.firstName} {form.middleName} {url.lastName}";
  // console.log("value", value, "dataObject", dataObject);
  
  try {
    return parseTemplate(value, dataObject);
  } catch (error) {
    console.error("Error parsing template:", error);
    return value;
  }

}
